import * as Dialog from "@radix-ui/react-dialog";
import {
	AgencyBillingError,
	BankAccountType,
	CURRENCY_LIST,
	ConnectNoAuthAccountSchema,
	CreateCustomProfileSchema,
	CustomProfilePreview,
	PayoutDetailsPreview,
	PayoutPreview,
	PhylloError,
	Platform,
	Profile,
	UpdateAgencyUser,
	UpdateAgencyUserSchema,
	getPlatformDisplayName,
	nonNull,
} from "@withjuly/fabric";
import { Chart, ChartDataset, registerables } from "chart.js";
import "chartjs-adapter-moment";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Line } from "react-chartjs-2";
import { useBreakpoint } from "~/utils/hooks/breakpoint";
import { PlatformData } from "../../Mediakits/store";
import { formatNumber } from "../../Mediakits/utils";
import { RouterOutput, trpc } from "../../Utility/trpc";
import { CreatorProfilePicture } from "../CreatorProfilePicture";
import {
	DropdownMenu,
	TabBar,
	Tag,
	IconButton,
	TagProps,
	Sidebar,
	ScrollArea,
	Button,
	Label,
	Modal,
	ToggleSwitch,
	useToast,
	ProgressLoader,
	Input,
	DropdownInput,
	SearchCategoryInput,
	Table,
	Tooltip,
} from "@withjuly/solisv2";
import {
	ArrowUp,
	ArrowDown,
	CaretRight,
	CaretLeft,
	X,
	Prohibit,
	Check,
	Plus,
	Plugs,
	Warning,
	Spinner,
	PencilSimple,
	Trash,
	EyeSlash,
	Eye,
	CopySimple,
	MagnifyingGlass,
	Info,
	FileDashed,
	Bank,
} from "@withjuly/julycons/bold";
import { CaretDown, CaretUp, Lightning } from "@withjuly/julycons/fill";
import {
	Facebook,
	Instagram,
	Tiktok,
	Youtube,
	Twitch,
} from "@withjuly/julycons";
import { cx } from "@withjuly/frontend-common";
import { useZodForm } from "~/utils/hooks/zod-form";
import { ZodInput } from "../../Input/ZodInput";
import { FormProvider } from "react-hook-form";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { CustomProfileEditor } from "./CustomProfileEditor";
import posthog from "posthog-js";
import { useEvervault } from "@evervault/react";
import { useAgency } from "~/utils/context/agency";
import { Loader } from "@withjuly/solis";
import { getFrontendDomain } from "~/utils/urls";
import { CONTENT_OPTIONS } from "../ContentCategoryInput";
import { ToggleGroupSelect } from "../ToggleGroupSelect";
import { Item } from "@radix-ui/react-toggle-group";
import { getStatusAndText } from "~/pages/[agency_id]/payouts";
import { LocationPicker } from "~/components/LocationPicker";
import { useRouter } from "next/router";
import { useAuth } from "~/utils/context/auth";
import { AddCreatorEmailModal } from "../AddCreatorEmailModal";
import { ZodDropdownInput } from "~/components/Input";

Chart.register(...registerables);

type CreatorDetailsMenu =
	| "analytics"
	| "profiles"
	| "payouts"
	| "settings"
	| "content-categories"
	| "survey-responses";
type AgencyCreator = RouterOutput["agency"]["getCreatorDetails"];

export const CreatorDetailsModal = ({
	isOpen,
	setIsOpen,
	creatorUuid,
	onRemove,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	creatorUuid: string;
	onRemove: () => void;
}) => {
	const isMobile = useBreakpoint({ base: true, lg: false }) ?? false;

	const { data: creator, isLoading: isLoading } =
		trpc.agency.getCreatorDetails.useQuery(creatorUuid);

	const [activePage, setActivePage] = useState<CreatorDetailsMenu>("analytics");
	const [categories, setCategories] = useState(() => creator?.tags ?? []);

	useEffect(() => {
		if (creator) {
			setCategories(() => creator.tags);
		}
	}, [creator]);

	return (
		<Dialog.Root
			modal={isMobile}
			open={isOpen}
			onOpenChange={(isOpen) => {
				setIsOpen(isOpen);
				if (!isOpen) {
					setActivePage(() => "analytics");
				}
			}}
		>
			<Dialog.DialogPortal style={{ pointerEvents: "none" }}>
				<Dialog.Overlay
					className="fixed inset-0 top-16 z-[20] bg-black/50 lg:top-0"
					style={{ pointerEvents: "none" }}
				/>
				<Dialog.Content
					className="bg-surface-primary border-stroke-secondary fixed inset-0 z-[20] mt-16 flex rounded-3xl border focus:outline-none data-[state=closed]:animate-[fade-out_0.10s_ease-in-out_forwards] data-[state=open]:animate-[fade-in_0.10s_ease-in-out] lg:m-8"
					onInteractOutside={(e) => e.preventDefault()}
				>
					<Dialog.Close className="absolute right-4 top-3 z-[100] rounded transition-all duration-150 ease-in-out focus:outline-none">
						<IconButton
							size="md"
							variant="blank-primary"
							aria-label="Close modal"
							icon={X}
							onClick={() => setActivePage("analytics")}
						/>
					</Dialog.Close>
					{!creator || isLoading ? (
						<div className="flex w-full items-center justify-center">
							<Loader />
						</div>
					) : (
						<div className="flex w-full">
							<div className="w-[352px] min-w-[352px] max-w-[352px]">
								<CreatorDetailsMenu creator={creator} setPage={setActivePage} />
							</div>

							<div className="flex h-full w-full flex-col items-center pt-8">
								{activePage === "analytics" ? (
									<AudienceTab creator={creator} />
								) : null}
								{activePage === "payouts" ? (
									<PayoutsTab creator={creator} />
								) : null}
								{activePage === "profiles" ? (
									<div className="flex h-full w-full items-center justify-center">
										<ProfilesTab creator={creator} />
									</div>
								) : null}
								{activePage === "content-categories" ? (
									<ContentCategoriesTab
										creator={creator}
										categories={categories}
										setCategories={setCategories}
									/>
								) : null}
								{activePage === "survey-responses" ? (
									<SurveyResponsesTab creator={creator} />
								) : null}
								{activePage === "settings" ? (
									<ScrollArea className="flex h-full flex-col overflow-y-scroll">
										<div className="flex h-full w-full items-center justify-center">
											<SettingsTab creator={creator} onRemove={onRemove} />
										</div>
									</ScrollArea>
								) : null}
							</div>
						</div>
					)}
				</Dialog.Content>
			</Dialog.DialogPortal>
		</Dialog.Root>
	);
};

const CreatorDetailsMenu = ({
	creator,
	setPage,
}: {
	creator: AgencyCreator;
	setPage: (page: CreatorDetailsMenu) => void;
}) => {
	const isNoAuthEnabled = useFeatureFlagEnabled("no-auth-talent");
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { agencyProfile } = useAgency();

	const menuLinks = [
		{
			name: "Analytics",
			onClick: () => {
				setSelectedIndex(0);
				setPage("analytics");
			},
			enabled: true,
		},
		{
			name: "Social Media Profiles",
			onClick: () => {
				setSelectedIndex(1);
				setPage("profiles");
			},
			enabled: isNoAuthEnabled,
		},
		{
			name: "Content Categories",
			onClick: () => {
				setSelectedIndex(2);
				setPage("content-categories");
			},
			enabled: true,
		},
		{
			name: "Survey Responses",
			onClick: () => {
				setSelectedIndex(3);
				setPage("survey-responses");
			},
			enabled: true,
		},
		{
			name: "Payments",
			onClick: () => {
				setSelectedIndex(4);
				setPage("payouts");
			},
			enabled:
				(agencyProfile?.isPaymentsEnabled ?? false) &&
				agencyProfile?.paymentsApplicaionStatus === "ACCEPTED",
		},
		{
			name: "Settings",
			onClick: () => {
				setSelectedIndex(5);
				setPage("settings");
			},
			enabled: true,
		},
	];

	return (
		<div className="relative flex h-full w-full flex-col">
			<Sidebar.Root className="h-[calc(100vh-64px)] w-[352px] min-w-[352px] max-w-[352px] rounded-bl-3xl rounded-tl-3xl lg:h-[calc(100vh-64px)] lg:rounded-bl-3xl lg:rounded-tl-3xl">
				<Sidebar.Links>
					<div className="ml-4 flex items-center gap-3">
						<CreatorProfilePicture creator={creator} size={40} />
						<p className="text-header-xl font-repro">
							{creator.user.displayName ??
								`${creator.user.firstName} ${creator.user.lastName}`}
						</p>
					</div>
					<Sidebar.Section>
						{menuLinks
							.filter((link) => link.enabled)
							.map((link, i) => {
								return (
									<Sidebar.Page
										key={i}
										selected={selectedIndex === i}
										onClick={link.onClick}
									>
										<div className="flex w-full items-center justify-between">
											{link.name}
										</div>
									</Sidebar.Page>
								);
							})}
					</Sidebar.Section>
				</Sidebar.Links>
			</Sidebar.Root>
		</div>
	);
};

const isPlatformNoAuth = (platform: Platform) => {
	return (
		platform === Platform.INSTAGRAM_NOAUTH ||
		platform === Platform.TIKTOK_NOAUTH ||
		platform === Platform.YOUTUBE_NOAUTH
	);
};

const AudienceTab = ({ creator }: { creator: AgencyCreator }) => {
	const TIME_RANGES = [
		{
			name: "past month",
			value: 1000 * 60 * 60 * 24 * 30,
		},
		{
			name: "past 6 months",
			value: 1000 * 60 * 60 * 24 * 30 * 6,
		},
		{
			name: "past year",
			value: 1000 * 60 * 60 * 24 * 365,
		},
	] as const;
	const PLATFORMS = [
		"instagram",
		"tiktok",
		"youtube",
		"facebook",
		"twitch",
	] as const;

	const [timeRange, setTimeRange] =
		useState<(typeof TIME_RANGES)[number]["name"]>("past month");
	const [selectedTab, setSelectedTab] = useState(() => "instagram");
	const [youtubeAccountIndex, setYouTubeAccountIndex] = useState(() => 0);
	const [instagramAccountIndex, setInstagramAccountIndex] = useState(() => 0);
	const [tiktokAccountIndex, setTikTokAccountIndex] = useState(() => 0);
	const [facebookAccountIndex, setFacebookAccountIndex] = useState(() => 0);
	const [twitchAccountIndex, setTwitchAccountIndex] = useState(() => 0);

	const [accounts, index] = useMemo(() => {
		const platformProfiles = creator.connectedAccounts
			.filter(
				(a) =>
					a.platformProfile?.isActive &&
					(a.platformProfile?.platform === selectedTab ||
						a.platformProfile?.platform === `${selectedTab}-noauth`),
			)
			?.map((acct) => {
				return acct.platformProfile;
			});
		if (selectedTab === "instagram") {
			return [platformProfiles, instagramAccountIndex];
		} else if (selectedTab === "youtube") {
			return [platformProfiles, youtubeAccountIndex];
		} else if (selectedTab === "facebook") {
			return [platformProfiles, facebookAccountIndex];
		} else if (selectedTab === "twitch") {
			return [platformProfiles, twitchAccountIndex];
		} else {
			return [platformProfiles, tiktokAccountIndex];
		}
	}, [
		creator.connectedAccounts,
		selectedTab,
		instagramAccountIndex,
		youtubeAccountIndex,
		facebookAccountIndex,
		tiktokAccountIndex,
		twitchAccountIndex,
	]);

	const { data: analyticsProfiles } = trpc.agency.getAnalytics.useQuery({
		duration: TIME_RANGES.find((range) => range.name === timeRange)?.value ?? 0,
		creatorProfileUuid: creator.uuid,
	});

	const datasets = useMemo(() => {
		const activeAccount = accounts[index] ?? null;

		return accounts
			.map((account) => {
				const profileAnalytics = analyticsProfiles?.find(
					(profile) => profile.uuid === account?.uuid,
				);

				if (!profileAnalytics) {
					return undefined;
				}

				let platformColor = "#30DAFF";
				let label = "Instagram";
				if (selectedTab === "tiktok") {
					platformColor = "#F42284";
					label = "Tiktok";
				} else if (selectedTab === "youtube") {
					platformColor = "#F52E38";
					label = " Youtube";
				} else if (selectedTab === "facebook") {
					// Use same colour as Instagram
					label = " Facebook";
				} else if (selectedTab === "twitch") {
					platformColor = "#9158FF";
					label = " Twitch";
				}

				let activeColor = "";
				if (activeAccount?.uuid === account?.uuid) {
					activeColor = platformColor;
				} else {
					activeColor = `${platformColor}4D`;
				}

				return {
					label: label,
					data: profileAnalytics.analytics.map((a) => ({
						x: a.createdAt,
						y: a.analytics.followers,
					})),
					borderDash: [],
					borderCapStyle: "round",
					pointBackgroundColor: activeColor,
					pointBorderColor: activeColor,
					borderColor: activeColor,
					tension: 0.4,
				};
			})
			.filter(nonNull);
	}, [accounts, analyticsProfiles, index, selectedTab]);

	const getXAxisUnit = () => {
		if (timeRange === "past month") {
			return "day";
		} else if (timeRange === "past 6 months") {
			return "week";
		} else if (timeRange === "past year") {
			return "month";
		}
		return "day";
	};

	return (
		<div className="flex h-full flex-col">
			<div className="flex flex-col gap-8 px-8">
				<TabBar.Root
					defaultValue="instagram"
					onValueChange={(value) => setSelectedTab(value)}
				>
					<TabBar.List>
						{PLATFORMS.map((platform) => {
							return (
								<TabBar.Trigger key={platform} value={platform}>
									{`${platform[0]?.toUpperCase()}${platform.slice(1)}`}
								</TabBar.Trigger>
							);
						})}
					</TabBar.List>
				</TabBar.Root>

				<div className="flex w-full items-center">
					{selectedTab === "instagram" ? (
						<PlatformsCard
							key={selectedTab}
							platform={selectedTab}
							accountIndex={index}
							setAccountIndex={setInstagramAccountIndex}
							profiles={accounts}
							analyticsProfiles={analyticsProfiles ?? []}
						/>
					) : null}
					{selectedTab === "youtube" ? (
						<PlatformsCard
							key={selectedTab}
							platform={selectedTab}
							accountIndex={index}
							setAccountIndex={setYouTubeAccountIndex}
							profiles={accounts}
							analyticsProfiles={analyticsProfiles ?? []}
						/>
					) : null}
					{selectedTab === "tiktok" ? (
						<PlatformsCard
							key={selectedTab}
							platform={selectedTab}
							accountIndex={index}
							setAccountIndex={setTikTokAccountIndex}
							profiles={accounts}
							analyticsProfiles={analyticsProfiles ?? []}
						/>
					) : null}
					{selectedTab === "facebook" ? (
						<PlatformsCard
							key={selectedTab}
							platform={selectedTab}
							accountIndex={index}
							setAccountIndex={setFacebookAccountIndex}
							profiles={accounts}
							analyticsProfiles={analyticsProfiles ?? []}
						/>
					) : null}
					{selectedTab === "twitch" ? (
						<PlatformsCard
							key={selectedTab}
							platform={selectedTab}
							accountIndex={index}
							setAccountIndex={setTwitchAccountIndex}
							profiles={accounts}
							analyticsProfiles={analyticsProfiles ?? []}
						/>
					) : null}
				</div>
			</div>

			<div className="bg-stroke-secondary mt-8 h-px w-full" />
			<ScrollArea className="h-full overflow-y-scroll">
				<div className="w-[calc(100vw-416px)] min-w-[calc(100vw-416px)] max-w-[calc(100vw-416px)] px-8 pb-8">
					<div className="my-6 flex items-center gap-1">
						<p className="text-overline-xs font-repro text-text-secondary">
							FOLLOWING OVER
						</p>
						<DropdownMenu.Root>
							<DropdownMenu.Trigger variant="custom">
								<p className="text-overline-xs text-text-primary font-repro flex cursor-pointer items-center gap-1">
									{timeRange.toUpperCase()} <CaretDown />
								</p>
							</DropdownMenu.Trigger>
							<DropdownMenu.Content>
								{TIME_RANGES.map((range) => (
									<DropdownMenu.Item
										key={range.name}
										onSelect={() => setTimeRange(range.name)}
									>
										{range.name}
									</DropdownMenu.Item>
								))}
							</DropdownMenu.Content>
						</DropdownMenu.Root>
					</div>

					<Line
						className="border-stroke-secondary bg-surface-primary h-full max-h-[400px] min-h-[200px] w-full rounded-2xl border p-4"
						width="100%"
						options={{
							responsive: true,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									mode: "index",
									intersect: false,
								},
							},
							scales: {
								y: {
									grid: {
										color: "#F4F9FE0D",
									},
									grace: "10%",
									ticks: {
										callback: (value) =>
											formatNumber(Math.round(value as number)),
									},
									border: {
										display: false,
									},
								},
								x: {
									grid: {
										display: false,
									},
									type: "time",
									time: {
										unit: getXAxisUnit(),
									},
									border: {
										display: false,
									},
								},
							},
						}}
						data={{
							datasets: (datasets as ChartDataset<"line">[]) ?? [],
						}}
					/>
				</div>
			</ScrollArea>
		</div>
	);
};

interface PlatformsCardProps {
	accountIndex: number;
	setAccountIndex: React.Dispatch<React.SetStateAction<number>>;
	profiles: AgencyCreator["connectedAccounts"][number]["platformProfile"][];
	platform: "instagram" | "tiktok" | "youtube" | "facebook" | "twitch";
	analyticsProfiles: RouterOutput["agency"]["getAnalytics"];
}

const PlatformsCard = ({
	accountIndex,
	setAccountIndex,
	profiles,
	platform,
	analyticsProfiles,
}: PlatformsCardProps) => {
	const platformData = PlatformData[platform];

	const getProfilePercentChange = (profileUuid: string) => {
		const profileAnalytics = analyticsProfiles?.find(
			(profile) => profile.uuid === profileUuid,
		);

		if (!profileAnalytics) {
			return 0;
		}
		const validAnalytics = profileAnalytics.analytics.filter(
			(analyticsProfile) => analyticsProfile.analytics.followers !== undefined,
		);
		const previous = validAnalytics[0]?.analytics.followers ?? 0;
		const current =
			validAnalytics[validAnalytics.length - 1]?.analytics.followers ?? 0;

		return previous === 0 ? 0 : ((current - previous) / previous) * 100;
	};

	const percentChange: number = getProfilePercentChange(
		profiles[accountIndex]?.uuid ?? "",
	);

	let percentChangeString = "0%";
	let changeIcon = ArrowUp;
	let statusColor: TagProps["color"] = "graphite";
	if (percentChange > 0) {
		percentChangeString = `${percentChange.toFixed(1)}%`;
		changeIcon = ArrowUp;
		statusColor = "green";
	} else if (percentChange < 0) {
		percentChangeString = `${-percentChange.toFixed(1)}%`;
		changeIcon = ArrowDown;
		statusColor = "red";
	}

	let Icon = <Instagram />;

	if (platform === Platform.INSTAGRAM) {
		Icon = <Instagram />;
	} else if (platform === Platform.TIKTOK) {
		Icon = <Tiktok />;
	} else if (platform === Platform.YOUTUBE) {
		Icon = <Youtube />;
	} else if (platform === Platform.FACEBOOK) {
		Icon = <Facebook />;
	} else if (platform === Platform.TWITCH) {
		Icon = <Twitch />;
	}

	if (profiles.length === 0 || !profiles) {
		return (
			<div
				key={platform}
				className="border-stroke-secondary bg-surface-secondary flex w-full flex-col items-center gap-4 rounded-2xl border p-6"
			>
				<div className="flex w-full flex-row justify-between gap-3">
					<div className="flex flex-col ">
						<span className="font-repro text-button-md text-text-placeholder flex items-center gap-2">
							{Icon} {platformData.name}
						</span>
						<p className="text-paragraph-sm font-repro text-text-placeholder">
							No account connected
						</p>
					</div>
				</div>
				<div className="flex h-[44px] w-full flex-row items-baseline justify-between gap-3">
					<p className="text-header-3xl font-repro text-text-placeholder">-</p>
					<Tag text="0%" size="lg" color="graphite" leftIcon={Prohibit} />
				</div>
			</div>
		);
	}

	return (
		<div
			key={platform}
			className="border-stroke-secondary bg-surface-secondary flex w-full flex-col items-center gap-4 rounded-2xl border p-6"
		>
			<div className="flex w-full flex-row justify-between gap-3">
				<div className="flex flex-col">
					<span className="font-repro text-button-md text-text-primary flex items-center gap-2">
						{Icon} {platformData.name}
						{profiles.length > 1 ? (
							<span className="font-repro text-button-md text-text-primary">
								{`(${accountIndex + 1} of ${profiles.length})`}
							</span>
						) : null}
					</span>
					<p className="text-paragraph-sm text-text-secondary font-repro">
						{platform !== "youtube"
							? `@${profiles[accountIndex]?.username}`
							: profiles[accountIndex]?.username}
					</p>
				</div>
				{profiles.length > 1 ? (
					<div className="flex flex-row gap-2">
						<IconButton
							variant="secondary"
							size="xs"
							icon={CaretLeft}
							disabled={accountIndex === 0}
							onClick={() => setAccountIndex((idx) => idx - 1)}
						/>
						<IconButton
							variant="secondary"
							size="xs"
							icon={CaretRight}
							disabled={accountIndex === profiles.length - 1}
							onClick={() => setAccountIndex((idx) => idx + 1)}
						/>
					</div>
				) : null}
			</div>
			<div className="flex h-[44px] w-full flex-row items-baseline justify-between gap-3">
				<p className="text-header-3xl font-repro text-text-primary">
					{formatNumber(profiles[accountIndex]?.size ?? 0)}
				</p>
				<Tag
					text={`${percentChangeString}`}
					size="lg"
					color={statusColor}
					leftIcon={percentChange === 0 ? undefined : changeIcon}
				/>
			</div>
		</div>
	);
};

interface EditingProfile {
	uuid: string | null;
	isActive: boolean;
}

const ProfilesTab = ({ creator }: { creator: AgencyCreator }) => {
	const utils = trpc.useContext();
	const isNoAuthEnabled = useFeatureFlagEnabled("no-auth-talent") ?? false;
	const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(() => false);
	const [isCustomProfileModalOpen, setIsCustomProfileModalOpen] =
		useState(false);
	const [editingProfile, setEditingProfile] = useState<EditingProfile>({
		uuid: null,
		isActive: false,
	});
	const sendConnectionEmail = trpc.agency.sendConnectionEmail.useMutation();
	const [hasCopied, setHasCopied] = useState<boolean>(false);
	const { toast } = useToast();
	const sendConnectionLink = useCallback(() => {
		sendConnectionEmail.mutate(creator.uuid, {
			onSuccess: () => {
				toast({
					title: "Invite link sent",
					description: `July sent an invite link to ${creator.user.displayName ?? `${creator.user.firstName} ${creator.user.lastName}`}`,
					variant: "success",
				});
			},
		});
	}, [
		creator.user.displayName,
		creator.user.firstName,
		creator.user.lastName,
		creator.uuid,
		sendConnectionEmail,
		toast,
	]);
	const isCustomProfileEnabled =
		posthog?.isFeatureEnabled("custom-profile") ?? false;
	const { agencyProfile } = useAgency();
	const groupedCustomProfiles = useMemo(() => {
		const groupedCustomProfiles: Map<string, CustomProfilePreview[]> =
			new Map();

		creator.customProfiles.map((profile) => {
			const group = groupedCustomProfiles.get(profile.title);
			if (group) {
				group.push(profile);
				groupedCustomProfiles.set(profile.title, group);
			} else {
				groupedCustomProfiles.set(profile.title, [profile]);
			}
		});

		return groupedCustomProfiles;
	}, [creator.customProfiles]);

	const facebookProfiles = creator.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.FACEBOOK)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasFacebook = (facebookProfiles?.length ?? 0) > 0;

	const twitchProfiles = creator.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.TWITCH)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasTwitch = (twitchProfiles?.length ?? 0) > 0;

	if (editingProfile.uuid !== null) {
		return (
			<CustomProfileEditor
				onClickBack={() => setEditingProfile({ uuid: null, isActive: false })}
				profileUuid={editingProfile.uuid}
				creatorUuid={creator.uuid}
				isActive={editingProfile.isActive}
			/>
		);
	} else {
		return (
			<div className="flex h-full w-full max-w-[640px] flex-col gap-8">
				<div className="flex flex-col gap-6">
					<div className="flex justify-between">
						<div className="flex flex-col">
							<p className="text-button-sm font-repro">
								Account connection link
							</p>
							<p className="text-paragraph-xs font-repro text-text-tertiary">
								Link will be emailed to {creator.user.email}
							</p>
						</div>

						<div className="flex gap-3">
							<IconButton
								size="md"
								icon={hasCopied ? Check : CopySimple}
								variant="blank-primary"
								onClick={() => {
									setHasCopied(true);
									setTimeout(() => {
										setHasCopied(false);
									}, 1000);

									window.navigator.clipboard.writeText(
										`https://${getFrontendDomain()}/join/${agencyProfile?.username}`,
									);
									toast({
										title: "Invite Link copied",
										description: `The invite link has been copied to your clipboard`,
										variant: "success",
									});
								}}
							/>
							<Button
								variant="secondary"
								size="md"
								onClick={() => {
									if (creator.user.email.length === 0) {
										setIsAddEmailModalOpen(true);
									} else {
										sendConnectionLink();
									}
								}}
							>
								Resend link
							</Button>
						</div>
					</div>
				</div>

				<div className="bg-stroke-tertiary h-px w-full" />

				<ScrollArea className="h-full overflow-y-scroll">
					<div className="flex h-full w-full max-w-[640px] flex-col gap-8 pb-8">
						<InstagramAccounts
							creator={creator}
							isNoAuthEnabled={isNoAuthEnabled}
						/>
						<TiktokAccounts
							creator={creator}
							isNoAuthEnabled={isNoAuthEnabled}
						/>

						<YoutubeAccounts
							creator={creator}
							isNoAuthEnabled={isNoAuthEnabled}
						/>
						{hasFacebook ? <FacebookAccounts creator={creator} /> : null}
						{hasTwitch ? <TwitchAccounts creator={creator} /> : null}
						{isCustomProfileEnabled ? (
							<>
								<div className="bg-stroke-tertiary h-px w-full" />

								{[...groupedCustomProfiles.values()].map((group) =>
									group.map((p, index) => (
										<CustomProfile
											key={index}
											index={index}
											groupLength={group.length}
											onClickEdit={(uuid) =>
												setEditingProfile({ uuid: uuid, isActive: p.isActive })
											}
											profile={p}
										/>
									)),
								)}

								<Button
									variant="blank"
									leadingIcon={Plus}
									onClick={() => setIsCustomProfileModalOpen(true)}
								>
									Add custom profile
								</Button>
							</>
						) : null}
					</div>
				</ScrollArea>
				{isCustomProfileModalOpen ? (
					<AddCustomProfileModal
						isOpen={isCustomProfileModalOpen}
						setIsOpen={setIsCustomProfileModalOpen}
						creatorProfileUuid={creator.uuid}
						onSave={(uuid) => setEditingProfile({ uuid: uuid, isActive: true })}
					/>
				) : null}

				{isAddEmailModalOpen && creator.uuid ? (
					<AddCreatorEmailModal
						isOpen={isAddEmailModalOpen}
						setIsOpen={setIsAddEmailModalOpen}
						userUuid={creator.user.uuid}
						name={
							creator.user.displayName ??
							`${creator.user.firstName} ${creator.user.lastName}`
						}
						sendEmail={sendConnectionLink}
					/>
				) : null}
			</div>
		);
	}
};

interface CustomProfileProps {
	index: number;
	onClickEdit: (uuid: string) => void;
	profile: CustomProfilePreview;
	groupLength: number;
}

const CustomProfile: React.FC<CustomProfileProps> = ({
	index,
	onClickEdit,
	profile,
	groupLength,
}) => {
	const [isProfileActive, setIsProfileActive] = useState(profile.isActive);
	const updateCustomProfileActivation =
		trpc.agency.customProfiles.updateActivation.useMutation();
	const utils = trpc.useContext();
	return (
		<div className="bg-surface-secondary border-stroke-tertiary flex h-[56px] flex-col justify-center rounded-2xl border">
			<div className="flex items-center justify-between py-[7px] pl-4 pr-2">
				<div className="flex gap-4">
					<ToggleSwitch
						toggle={isProfileActive}
						onToggle={() => {
							updateCustomProfileActivation.mutateAsync(
								{
									profileUuid: profile.uuid,
									isActive: !isProfileActive,
								},
								{
									onSuccess: () => {
										utils.agency.getCreatorsPreview.invalidate();
										utils.agency.getCreatorDetails.invalidate();
									},
								},
							);
							setIsProfileActive(!isProfileActive);
						}}
					/>
					<div className="flex gap-3">
						<p className="font-repro text-paragraph-sm truncate">
							{profile.title}
						</p>
						{groupLength > 1 ? (
							<Label
								variant="overline"
								size="xs"
								color="tertiary"
							>{`${index + 1} of ${groupLength}`}</Label>
						) : null}
						<Tag color="pink" text="Custom profile" />
					</div>
				</div>
				<IconButton
					icon={PencilSimple}
					variant="blank-primary"
					size="sm"
					onClick={() => {
						onClickEdit(profile.uuid);
					}}
				/>
			</div>
		</div>
	);
};

const AddCustomProfileModal = ({
	isOpen,
	setIsOpen,
	creatorProfileUuid,
	onSave,
}: {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	creatorProfileUuid: string;
	onSave: (uuid: string) => void;
}) => {
	const createCustomProfile = trpc.agency.customProfiles.create.useMutation();
	const utils = trpc.useContext();
	const form = useZodForm({
		schema: CreateCustomProfileSchema,
		values: {
			creatorProfileUuid: creatorProfileUuid,
			title: "",
			username: "",
			accountUrl: "",
		},
		submit: async (data, { onFormError }) => {
			createCustomProfile.mutateAsync(data, {
				onSuccess: (values) => {
					setIsOpen(false);
					utils.agency.getCreatorsPreview.invalidate();
					utils.agency.getCreatorDetails.invalidate();
					onSave(values.uuid);
				},
				onError: (err) => {
					onFormError(err);
				},
			});
		},
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="lg">
			<Modal.Header
				title="Add a custom profile"
				description="Use custom profiles to showcase platforms or content July doesn’t directly support."
			/>
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex flex-col gap-6">
							<ZodInput
								name="title"
								label="Title"
								placeholder="Pinterest, X (Twitter), As Seen In, etc."
							/>
							<ZodInput
								name="username"
								label="Name or Username (optional)"
								placeholder="Add name or username"
							/>
							<ZodInput
								name="accountUrl"
								label="Link to Profile (optional)"
								placeholder="Add a link to the profile"
							/>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>

			<Modal.Footer
				buttons="primary"
				primaryLabel="Continue"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
			/>
		</Modal.Root>
	);
};

const InstagramAccounts = ({
	creator,
	isNoAuthEnabled,
}: {
	creator: AgencyCreator;
	isNoAuthEnabled: boolean;
}) => {
	const instagramProfiles = creator.connectedAccounts
		.filter(
			(p) =>
				p.platformProfile?.platform === Platform.INSTAGRAM ||
				p.platformProfile?.platform === Platform.INSTAGRAM_NOAUTH,
		)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasInstagram = (instagramProfiles?.length ?? 0) > 0;
	const [isConnectPlatforModalOpen, setIsConnectPlatforModalOpen] =
		useState(false);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<p className="text-button-md font-repro flex h-[26px] items-center gap-2">
					<Instagram className="h-[14px] w-[14px]" /> Instagram
				</p>
				{isNoAuthEnabled ? (
					<Button
						variant="blank"
						leadingIcon={Plus}
						size="xs"
						onClick={() => setIsConnectPlatforModalOpen(true)}
					>
						Add No Auth account
					</Button>
				) : null}
			</div>
			<div className="bg-surface-secondary border-stroke-tertiary flex flex-col rounded-2xl border">
				{hasInstagram ? (
					instagramProfiles?.map((profile, index) => (
						<AccountBlock
							key={`${profile.uuid}-${profile.isActive}`}
							index={index}
							isAuth={!isPlatformNoAuth(profile.platform)}
							disabled={!isNoAuthEnabled && isPlatformNoAuth(profile.platform)}
							profile={profile}
							creatorUuid={creator.uuid}
						/>
					))
				) : (
					<div className="flex h-[56px] items-center justify-between px-4 py-3">
						<div className="flex gap-4">
							<p className="font-repro text-paragraph-sm text-text-placeholder truncate">
								No accounts connected
							</p>
						</div>
					</div>
				)}
			</div>
			{isConnectPlatforModalOpen ? (
				<ConnectPlatformModal
					userUuid={creator.user.uuid}
					isOpen={isConnectPlatforModalOpen}
					setIsOpen={setIsConnectPlatforModalOpen}
					name={creator.user.firstName}
					platform={Platform.INSTAGRAM}
				/>
			) : null}
		</div>
	);
};

const YoutubeAccounts = ({
	creator,
	isNoAuthEnabled,
}: {
	creator: AgencyCreator;
	isNoAuthEnabled: boolean;
}) => {
	const youtubeProfiles = creator.connectedAccounts
		.filter(
			(p) =>
				p.platformProfile?.platform === Platform.YOUTUBE ||
				p.platformProfile?.platform === Platform.YOUTUBE_NOAUTH,
		)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasYoutube = (youtubeProfiles?.length ?? 0) > 0;
	const [isConnectPlatforModalOpen, setIsConnectPlatforModalOpen] =
		useState(false);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<p className="text-button-md font-repro flex h-[26px] items-center gap-2">
					<Youtube className="h-[14px] w-[14px]" /> YouTube
				</p>
				{isNoAuthEnabled ? (
					<Button
						variant="blank"
						leadingIcon={Plus}
						size="xs"
						onClick={() => setIsConnectPlatforModalOpen(true)}
					>
						Add No Auth account
					</Button>
				) : null}
			</div>
			<div className="bg-surface-secondary border-stroke-tertiary flex flex-col rounded-2xl border">
				{hasYoutube ? (
					youtubeProfiles?.map((profile, index) => (
						<AccountBlock
							key={`${profile.uuid}-${profile.isActive}`}
							index={index}
							isAuth={!isPlatformNoAuth(profile.platform)}
							disabled={!isNoAuthEnabled && isPlatformNoAuth(profile.platform)}
							profile={profile}
							creatorUuid={creator.uuid}
						/>
					))
				) : (
					<div className="flex h-[56px] items-center justify-between px-4 py-3">
						<div className="flex gap-4">
							<p className="font-repro text-paragraph-sm text-text-placeholder truncate">
								No accounts connected
							</p>
						</div>
					</div>
				)}
			</div>
			{isConnectPlatforModalOpen ? (
				<ConnectPlatformModal
					userUuid={creator.user.uuid}
					isOpen={isConnectPlatforModalOpen}
					setIsOpen={setIsConnectPlatforModalOpen}
					name={creator.user.firstName}
					platform={Platform.YOUTUBE}
				/>
			) : null}
		</div>
	);
};

const TiktokAccounts = ({
	creator,
	isNoAuthEnabled,
}: {
	creator: AgencyCreator;
	isNoAuthEnabled: boolean;
}) => {
	const tiktokProfiles = creator.connectedAccounts
		.filter(
			(p) =>
				p.platformProfile?.platform === Platform.TIKTOK ||
				p.platformProfile?.platform === Platform.TIKTOK_NOAUTH,
		)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasTiktok = (tiktokProfiles?.length ?? 0) > 0;
	const [isConnectPlatforModalOpen, setIsConnectPlatforModalOpen] =
		useState(false);

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<p className="text-button-md font-repro flex h-[26px] items-center gap-2">
					<Tiktok className="h-[14px] w-[14px]" /> TikTok
				</p>
				{isNoAuthEnabled ? (
					<Button
						variant="blank"
						leadingIcon={Plus}
						size="xs"
						onClick={() => setIsConnectPlatforModalOpen(true)}
					>
						Add No Auth account
					</Button>
				) : null}
			</div>
			<div className="bg-surface-secondary border-stroke-tertiary flex flex-col rounded-2xl border">
				{hasTiktok ? (
					tiktokProfiles?.map((profile, index) => (
						<AccountBlock
							key={`${profile.uuid}-${profile.isActive}`}
							index={index}
							isAuth={!isPlatformNoAuth(profile.platform)}
							disabled={!isNoAuthEnabled && isPlatformNoAuth(profile.platform)}
							profile={profile}
							creatorUuid={creator.uuid}
						/>
					))
				) : (
					<div className="flex h-[56px] items-center justify-between px-4 py-3">
						<div className="flex gap-4">
							<p className="font-repro text-paragraph-sm text-text-placeholder truncate">
								No accounts connected
							</p>
						</div>
					</div>
				)}
			</div>
			{isConnectPlatforModalOpen ? (
				<ConnectPlatformModal
					userUuid={creator.user.uuid}
					isOpen={isConnectPlatforModalOpen}
					setIsOpen={setIsConnectPlatforModalOpen}
					name={creator.user.firstName}
					platform={Platform.TIKTOK}
				/>
			) : null}
		</div>
	);
};

const FacebookAccounts = ({ creator }: { creator: AgencyCreator }) => {
	const facebookProfiles = creator.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.FACEBOOK)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasFacebook = (facebookProfiles?.length ?? 0) > 0;
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<p className="text-button-md font-repro flex h-[26px] items-center gap-2">
					<Facebook className="h-[16px] w-[16px]" /> Facebook
				</p>
			</div>
			<div className="bg-surface-secondary border-stroke-tertiary flex flex-col rounded-2xl border">
				{hasFacebook ? (
					facebookProfiles?.map((profile, index) => (
						<AccountBlock
							key={`${profile.uuid}-${profile.isActive}`}
							index={index}
							isAuth={true}
							disabled={false}
							profile={profile}
							creatorUuid={creator.uuid}
						/>
					))
				) : (
					<div className="flex h-[56px] items-center justify-between px-4 py-3">
						<div className="flex gap-4">
							<p className="font-repro text-paragraph-sm text-text-placeholder truncate">
								No accounts connected
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const TwitchAccounts = ({ creator }: { creator: AgencyCreator }) => {
	const twitchProfiles = creator.connectedAccounts
		.filter((p) => p.platformProfile?.platform === Platform.TWITCH)
		.map((p) => p.platformProfile)
		.filter(nonNull);
	const hasTwitch = (twitchProfiles?.length ?? 0) > 0;
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between">
				<p className="text-button-md font-repro flex h-[26px] items-center gap-2">
					<Twitch className="h-[14px] w-[14px]" /> Twitch
				</p>
			</div>
			<div className="bg-surface-secondary border-stroke-tertiary flex flex-col rounded-2xl border">
				{hasTwitch ? (
					twitchProfiles?.map((profile, index) => (
						<AccountBlock
							key={`${profile.uuid}-${profile.isActive}`}
							index={index}
							isAuth={true}
							disabled={false}
							profile={profile}
							creatorUuid={creator.uuid}
						/>
					))
				) : (
					<div className="flex h-[56px] items-center justify-between px-4 py-3">
						<div className="flex gap-4">
							<p className="font-repro text-paragraph-sm text-text-placeholder truncate">
								No accounts connected
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

interface AccountBlockProps {
	index: number;
	isAuth: boolean;
	disabled: boolean;
	profile: Profile;
	creatorUuid: string;
}
export const AccountBlock: React.FC<AccountBlockProps> = ({
	isAuth,
	profile,
	index,
	creatorUuid,
}) => {
	const [isProfileActive, setIsProfileActive] = useState(profile.isActive);
	const updatePlatformProfileActivation =
		trpc.agency.updatePlatformProfileActivation.useMutation();
	const deletePlatformProfile = trpc.agency.deletePlatformProfile.useMutation();
	const utils = trpc.useContext();
	const { toast } = useToast();
	const [isConfirmDisconnectModalOpen, setIsConfirmDisconnectModalOpen] =
		useState(false);

	const updateActivation = () => {
		updatePlatformProfileActivation.mutate(
			{
				profileUuid: profile.uuid,
				isActive: !isProfileActive,
			},
			{
				onSuccess: () => {
					utils.agency.getCreatorsPreview.invalidate();
					utils.agency.getCreatorDetails.invalidate();
					if (isProfileActive) {
						toast({
							title: "Account hidden",
							description: `${profile.platform === Platform.YOUTUBE || profile.platform === Platform.YOUTUBE_NOAUTH || profile.platform === Platform.FACEBOOK ? profile.username : `@${profile.username}`} is now hidden from all assets.`,
							variant: "success",
						});
					} else {
						toast({
							title: "Account visible",
							description: `${profile.platform === Platform.YOUTUBE || profile.platform === Platform.YOUTUBE_NOAUTH || profile.platform === Platform.FACEBOOK ? profile.username : `@${profile.username}`} is now visible across all assets.`,
							variant: "success",
						});
					}
				},
			},
		);
		setIsProfileActive(!isProfileActive);
	};

	const tag = useMemo(() => {
		if (profile.hasIssue) {
			return (
				<Tag
					text="Account connection error"
					color="orange"
					leftIcon={Warning}
				/>
			);
		} else if (isAuth) {
			return isProfileActive ? (
				<Tag text="Direct connection" color="green" leftIcon={Check} />
			) : (
				<Tag text="Direct connection (hidden)" color="graphite" />
			);
		} else if (profile.isPending) {
			return <Tag text="Pending" color="graphite" leftIcon={Spinner} />;
		} else if (!isAuth) {
			return isProfileActive ? (
				<Tag text="No Auth" color="brand" leftIcon={Lightning} />
			) : (
				<Tag text="No Auth (hidden)" color="graphite" />
			);
		}
	}, [isAuth, profile, isProfileActive]);
	return (
		<div
			className={cx(
				"flex h-[56px] items-center justify-between py-3 pl-4 pr-3",
				index !== 0 && "border-stroke-tertiary border-t",
			)}
			key={profile.uuid}
		>
			<div className="flex gap-4">
				<p
					className={`font-repro text-paragraph-sm truncate ${!isProfileActive && "text-text-placeholder"}`}
				>
					{profile.platform === Platform.YOUTUBE ||
					profile.platform === Platform.YOUTUBE_NOAUTH ||
					profile.platform === Platform.FACEBOOK
						? profile.username
						: `@${profile.username}`}
				</p>
			</div>
			<div className="flex items-center gap-2">
				{tag}

				<DropdownMenu.Root>
					<DropdownMenu.Trigger size="sm" />
					<DropdownMenu.Content>
						<DropdownMenu.Item
							icon={isProfileActive ? EyeSlash : Eye}
							onClick={(e) => {
								e.stopPropagation();
								updateActivation();
							}}
						>
							{isProfileActive ? "Hide account" : "Show account"}
						</DropdownMenu.Item>
						<DropdownMenu.Item
							icon={Trash}
							variant="danger"
							onClick={(e) => {
								e.stopPropagation();
								setIsConfirmDisconnectModalOpen(true);
							}}
						>
							Disconnect
						</DropdownMenu.Item>
					</DropdownMenu.Content>
				</DropdownMenu.Root>
			</div>
			<ConfirmDisconnectModal
				isOpen={isConfirmDisconnectModalOpen}
				setIsOpen={setIsConfirmDisconnectModalOpen}
				onDelete={() => {
					deletePlatformProfile.mutateAsync(
						{
							creatorUuid: creatorUuid,
							profileUuid: profile.uuid,
						},
						{
							onSuccess: () => {
								utils.agency.getCreatorsPreview.invalidate();
								utils.agency.getCreatorDetails.invalidate();
								toast({
									title: "Account disconnected",
								});
							},
						},
					);
				}}
				username={
					profile.platform === Platform.YOUTUBE ||
					profile.platform === Platform.YOUTUBE_NOAUTH
						? profile.username
						: `@${profile.username}`
				}
			/>
		</div>
	);
};

const ConnectPlatformModal = ({
	isOpen,
	platform,
	setIsOpen,
	userUuid,
}: {
	isOpen: boolean;
	platform: Platform;
	name: string;
	setIsOpen: (open: boolean) => void;
	userUuid: string;
}) => {
	const connectNoAuth = trpc.agency.connectNoAuthAccount.useMutation();
	const [isConnecting, setIsConnecting] = useState(false);
	const utils = trpc.useContext();
	const { toast } = useToast();
	const platformTitle = getPlatformDisplayName(platform);
	const form = useZodForm({
		schema: ConnectNoAuthAccountSchema,
		values: {
			userUuid: userUuid,
			platformUsernames: {
				instagramUsername: "",
				youtubeUsername: "",
				tiktokUsername: "",
			},
		},
		submit: async (values, { onFormError }) => {
			connectNoAuth.mutateAsync(values, {
				onSuccess: () => {
					setIsOpen(false);
					setIsConnecting(false);
					utils.agency.getCreatorsPreview.invalidate();
					utils.agency.getCreatorDetails.invalidate();
					toast({
						title: "Account connected",
						description: `The account has been added to the profile`,
						variant: "success",
					});
					form.reset();
				},
				onError: (error) => {
					const parsedError =
						AgencyBillingError.AgencyBillingErrorSchema.safeParse(error.data);

					const phylloError = PhylloError.PhylloErrorSchema.safeParse(
						error.data,
					);

					if (parsedError.success) {
						const error = parsedError.data;
						toast({
							variant: "error",
							title: error.message,
							description: error.description,
						});
					} else if (phylloError.success) {
						const error = phylloError.data;
						toast({
							variant: "error",
							title: error.message,
							description: error.description,
						});
					} else {
						onFormError(error);
					}

					setIsConnecting(false);
				},
			});
		},
	});
	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="md">
			{!isConnecting ? (
				<Modal.Header
					title={`Add ${platformTitle} profile (No Auth)`}
					description={
						<span>
							No Auth costs an additional $6/mo per creator. Add as many
							accounts as you want.{" "}
							<a
								href="https://withjuly.com/agency-resources/no-auth"
								className="text-paragraph-sm underline"
								target="_blank"
							>
								Learn more about No Auth
							</a>
						</span>
					}
				/>
			) : null}

			<Modal.Body>
				{isConnecting ? (
					<div className="my-20 flex h-full flex-col items-center justify-center">
						<div className="bg-sky-alpha-4 mb-4 w-fit rounded-[8px] p-2">
							<Plugs className="text-brand h-6 w-6" />
						</div>
						<div className="mb-8 flex flex-col items-center justify-center gap-[2px]">
							<p className="text-header-lg font-repro">Connecting account</p>
						</div>
						<ProgressLoader />
					</div>
				) : (
					<FormProvider {...form}>
						<form>
							<ZodInput
								label={
									platform === Platform.YOUTUBE
										? "Link to youtube channel"
										: platform
								}
								name={`platformUsernames.${platform}Username`}
								placeholder={
									platform === Platform.YOUTUBE ? "https://" : "@username"
								}
							/>
						</form>
					</FormProvider>
				)}
			</Modal.Body>
			{!isConnecting ? (
				<Modal.Footer
					primaryLabel="Connect account"
					onPrimaryClicked={() => {
						setIsConnecting(true);
						form.onSubmit();
					}}
					buttons="primary"
					isPrimaryDisabled={!form.formState.isValid}
				/>
			) : null}
		</Modal.Root>
	);
};

interface PayoutsTabProps {
	creator: AgencyCreator;
}

const PayoutsTab: React.FC<PayoutsTabProps> = ({ creator }) => {
	const utils = trpc.useContext();
	const { data: payoutDetails, isLoading: arePayoutDetailsLoading } =
		trpc.agency.payments.getTalentPayoutDetails.useQuery(creator.uuid, {
			refetchOnWindowFocus: false,
		});
	const setTalentPayoutDetails =
		trpc.agency.payments.setTalentPayoutDetails.useMutation();
	const { data: payoutPreviews, isLoading: isLoadingPreviews } =
		trpc.agency.payments.getTalentPayoutPreviews.useQuery(creator.uuid, {
			refetchOnWindowFocus: false,
		});
	const sendBankDetailsRequestEmail =
		trpc.agency.sendBankDetailsRequestEmail.useMutation();

	const evervault = useEvervault();
	const { toast } = useToast();

	const [isEditing, setIsEditing] = useState(() => false);
	const [nameOnAccount, setNameOnAccount] = useState(() => "");
	const [accountType, setAccountType] = useState<BankAccountType>(
		() => BankAccountType.enum.individual,
	);
	const [accountNumber, setAccountNumber] = useState(() => "");
	const [routingNumber, setRoutingNumber] = useState(() => "");

	const routingNumberRef = useRef<HTMLInputElement>(null);

	const sendBankDetailsRequest = useCallback(() => {
		sendBankDetailsRequestEmail.mutate(creator.uuid, {
			onSuccess: () => {
				toast({
					title: "Request sent",
					description: `July sent ${creator.user.firstName} an email asking them to connect their bank account.`,
					variant: "success",
				});
			},
		});
	}, [
		creator.user.firstName,
		creator.uuid,
		sendBankDetailsRequestEmail,
		toast,
	]);

	const decryptPaymentPreview = useCallback(
		async (payoutDetails: PayoutDetailsPreview) => {
			if (!evervault) {
				toast({
					variant: "error",
					title: "Could not retrieve talent payment details",
					description:
						"We could not retrieve the talent's payment details. Please try again later.",
				});
				return;
			}

			//
			if (!payoutDetails) {
				return;
			}

			const descryptedAccountNumber = await evervault.decrypt(
				payoutDetails.accountNumberToken,
				payoutDetails.accountNumber,
			);
			const descryptedRoutingNumber = await evervault.decrypt(
				payoutDetails.routingNumberToken,
				payoutDetails.routingNumber,
			);

			// The evervault API claims that the return type of `decrypt` is `{ value: unknown }` but it's
			// actually just a string so we have to assert these types to make it work.
			//
			// If we start getting some unexpected behavior around this page this is the first place that
			// we should check.
			setAccountNumber(() => descryptedAccountNumber as unknown as string);
			setRoutingNumber(() => descryptedRoutingNumber as unknown as string);
		},
		[evervault, toast],
	);

	// Only let the user save if both the account and routing number are not empty.
	//
	// If there is some better way to ensure that thet account and routing number are correct we should
	// also be doing that here.
	const canSave = useMemo(() => {
		return routingNumber !== "" && accountNumber !== "" && nameOnAccount !== "";
	}, [accountNumber, nameOnAccount, routingNumber]);

	// Encrypt the account and routing number before sending them to the backend to be inserted into
	// the database.
	//
	// After the account and routing number have been set disable the payments form and set the account
	// set the inputs to the last four digits of both the account and routing number.
	const onSave = useCallback(async () => {
		if (!evervault) {
			toast({
				variant: "error",
				title: "Could not update talent payment details",
				description:
					"We could not update the talent's payment details. Please try again later.",
			});
			return;
		}

		const encryptedAccountNumber = await evervault.encrypt(
			accountNumber.trim(),
		);
		const encryptedRoutingNumber = await evervault.encrypt(
			routingNumber.trim(),
		);

		setTalentPayoutDetails.mutate(
			{
				creatorProfileUuid: creator.uuid,
				details: {
					accountNumber: encryptedAccountNumber,
					routingNumber: encryptedRoutingNumber,
					accountType,
					nameOnAccount,
				},
			},
			{
				onSuccess: () => {
					toast({
						variant: "success",
						title: "Payout details updated",
						description: `${creator.user.firstName}'s payout details have been updated'`,
					});

					utils.agency.payments.getTalentPayoutDetails.invalidate();
					setIsEditing(() => false);
				},
				onError: () => {
					toast({
						variant: "error",
						title: "Unable to update payout details",
						description: `We were unable to update the payout details. Please try again later`,
					});
				},
			},
		);
	}, [
		accountNumber,
		accountType,
		creator.user.firstName,
		creator.uuid,
		evervault,
		nameOnAccount,
		routingNumber,
		setTalentPayoutDetails,
		toast,
		utils.agency.payments.getTalentPayoutDetails,
	]);

	const onCancel = () => {
		if (payoutDetails) {
			decryptPaymentPreview(payoutDetails);
		}
		setIsEditing(false);
	};

	// Once the encrypted data is fetched from the server decrypt it before putting setting it in the
	// state variables.
	useEffect(() => {
		if (payoutDetails) {
			setNameOnAccount(() => payoutDetails.nameOnAccount);
			setAccountType(() => payoutDetails.accountType);
			decryptPaymentPreview(payoutDetails);
		}
	}, [decryptPaymentPreview, payoutDetails]);

	const tableBody = useMemo(() => {
		if (isLoadingPreviews) {
			return (
				<div className="flex h-full w-full items-center justify-center">
					<Loader />
				</div>
			);
		} else if (payoutPreviews?.length === 0) {
			return (
				<div className="flex h-full w-full items-center justify-center">
					<p className="font-repro text-paragraph-md text-text-placeholder">
						No payouts yet
					</p>
				</div>
			);
		} else {
			return (
				<ScrollArea className="h-full px-4">
					{(payoutPreviews ?? []).map((payout) => {
						return <PayoutRow key={payout.uuid} {...payout} />;
					})}
				</ScrollArea>
			);
		}
	}, [isLoadingPreviews, payoutPreviews]);

	return (
		<div className="flex h-full w-full max-w-[800px] flex-col gap-12 overflow-hidden px-8 pb-8">
			<div className="flex w-full flex-col gap-8">
				<div className="flex flex-col gap-[2px]">
					<p className="font-repro text-button-md">Bank Details</p>
					<p className="font-repro text-paragraph-sm text-text-secondary">
						{creator.user.firstName}&apos;s payouts will be sent to this bank
						account via ACH transfer.
					</p>
				</div>

				<div className="flex flex-col gap-4">
					<div className="flex items-center gap-4">
						<Input
							label="Name on account"
							placeholder="Full name"
							value={nameOnAccount}
							onChange={(e) => setNameOnAccount(() => e.target.value)}
							disabled={!isEditing}
						/>
						<DropdownInput.Root
							label="Account type"
							value={accountType}
							disabled={!isEditing}
							onValueChange={(v) =>
								setAccountType(() => {
									if (v === BankAccountType.enum.company) {
										return BankAccountType.enum.company;
									} else {
										return BankAccountType.enum.individual;
									}
								})
							}
						>
							<DropdownInput.Item value={BankAccountType.enum.individual}>
								Individual
							</DropdownInput.Item>
							<DropdownInput.Item value={BankAccountType.enum.company}>
								Company
							</DropdownInput.Item>
						</DropdownInput.Root>
					</div>

					<div className="flex items-center gap-4">
						<Input
							ref={routingNumberRef}
							label="Routing number"
							placeholder="Add routing number"
							value={routingNumber}
							onChange={(e) => setRoutingNumber(() => e.target.value)}
							disabled={!isEditing}
						/>
						<Input
							label="Account number"
							placeholder="Add account number"
							value={
								isEditing
									? accountNumber
									: accountNumber.replace(/^.+(?=.{4}$)/, (match) =>
											"*".repeat(match.length),
										)
							}
							onChange={(e) => setAccountNumber(() => e.target.value)}
							disabled={!isEditing}
						/>
					</div>
				</div>
				<div className="flex flex-col gap-5">
					{isEditing ? (
						<div className="flex w-full flex-row gap-4">
							<Button onClick={onCancel} className="flex-1" variant="secondary">
								Cancel
							</Button>
							<Button onClick={onSave} disabled={!canSave} className="flex-1">
								Save changes
							</Button>
						</div>
					) : (
						<Button
							variant="secondary"
							disabled={arePayoutDetailsLoading}
							isLoading={arePayoutDetailsLoading}
							onClick={() => {
								setIsEditing(() => true);
								routingNumberRef.current?.focus();
							}}
						>
							Edit
						</Button>
					)}
					<Button
						onClick={() => sendBankDetailsRequest()}
						leadingIcon={Bank}
						variant="blank"
					>
						Request bank details from talent
					</Button>
				</div>
			</div>

			<div className="flex h-full flex-col gap-4">
				<p className="text-button-md font-repro">Payout History</p>

				<div className="rounded-solis-xl border-stroke-secondary bg-surface-secondary flex h-[calc(100vh-510px)] w-full flex-col border pt-2">
					<div className="px-4">
						<Table.Row className="h-auto pb-2">
							<Table.Cell className="w-full max-w-[160px]" variant="th">
								Date
							</Table.Cell>
							<Table.Cell variant="th">For payment from</Table.Cell>
							<Table.Cell variant="th" align="right">
								Amount
							</Table.Cell>
							<Table.Cell
								variant="th"
								align="right"
								className="w-full max-w-[128px]"
							>
								Status
							</Table.Cell>
						</Table.Row>
					</div>
					<div className="bg-stroke-secondary h-px w-full">
						{/** DIVIDER */}
					</div>
					{tableBody}
				</div>
			</div>
		</div>
	);
};

const PayoutRow: React.FC<PayoutPreview> = ({
	paidOutAt,
	forPayment,
	amount,
	status,
}) => {
	const statusCell = useMemo(() => {
		const [color, text, icon] = getStatusAndText(status);

		return (
			<Table.Cell
				variant="tag"
				text={text}
				color={color}
				size="md"
				align="right"
				leftIcon={icon}
				className="w-full max-w-[128px]"
			/>
		);
	}, [status]);

	return (
		<Table.Row>
			<Table.Cell className="w-full max-w-[160px]" variant="compact">
				{paidOutAt.toLocaleDateString("en-US", {
					day: "2-digit",
					month: "short",
					year: "numeric",
				})}
			</Table.Cell>
			<Table.Cell>{forPayment ? forPayment : "-"}</Table.Cell>
			<Table.Cell align="right">
				{amount.toLocaleString("en-US", {
					style: "currency",
					currency: "USD",
				})}
			</Table.Cell>
			{statusCell}
		</Table.Row>
	);
};

const SettingsTab = ({
	creator,
	onRemove,
}: {
	creator: AgencyCreator;
	onRemove: () => void;
}) => {
	const { agencyProfile } = useAgency();
	const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
	const [isExclusive, setIsExclusive] = useState<boolean>(
		creator.exclusivity === "exclusive",
	);
	const [selectedLocation, setSelectedLocation] = useState<string | undefined>(
		creator.user.location,
	);
	const [selectedManagerEmail, setSelectedManagerEmail] = useState<
		string | undefined
	>(undefined);

	const uploadRef = useRef<HTMLInputElement | null>(null);

	// TRPC

	const utils = trpc.useContext();
	const { data: members } = trpc.agency.getMembers.useQuery();
	const { data: manager } = trpc.agency.getManager.useQuery({
		creatorUuid: creator.uuid,
	});
	const linkManagerAndCreator = trpc.agency.linkManagerAndCreator.useMutation();
	const getUploadUrl = trpc.user.getProfilePictureUploadUrl.useMutation();
	const updateAgencyCreatorExclusivity =
		trpc.agency.updateAgencyCreatorExclusivity.useMutation({
			onSuccess: () => {
				utils.agency.getCreatorsPreview.invalidate();
				utils.agency.getCreatorDetails.invalidate();
			},
		});
	const updateAgencyCreatorProfilePicture =
		trpc.agency.updateAgencyCreatorProfilePicture.useMutation();
	const updateUser = trpc.agency.updateAgencyUser.useMutation({
		onSuccess: () => {
			utils.agency.getCreatorsPreview.invalidate();
			utils.agency.getCreatorDetails.invalidate();
		},
	});

	// Update Agency User Form

	const initialFormValues: UpdateAgencyUser = {
		creatorUuid: creator.uuid,
		firstName: creator.user?.firstName ?? "",
		lastName: creator.user?.lastName ?? "",
		displayName: creator.user.displayName,
		pronouns: creator.pronouns,
		email: creator.user.email,
		username: creator.username ?? "",
		location: selectedLocation,
		currency: creator.currency,
	};

	const updateAgencyUserForm = useZodForm({
		schema: UpdateAgencyUserSchema,
		values: initialFormValues,
		submit: async (data, { onFormError }) => {
			updateUser.mutate(data, {
				onSuccess: () =>
					toast({
						title: "User updated",
						description: "The account has been updated",
						variant: "success",
					}),
				onError: (error: unknown) => {
					onFormError(error);
				},
			});
		},
	});

	const fields = updateAgencyUserForm.watch();

	// Update Manager Handler

	useEffect(() => {
		setSelectedManagerEmail(manager?.email);
	}, [manager]);

	const updateCreatorManager = async (managerUuid: string) => {
		// Optimistically update the email within the dropdown
		const managerEmail: string | undefined =
			(members ?? []).find((member) => {
				return member.uuid === managerUuid;
			})?.email ?? "";
		setSelectedManagerEmail(managerEmail);

		linkManagerAndCreator.mutate(
			{
				creatorUuid: creator.uuid,
				memberUuid: managerUuid,
			},
			{
				onSuccess: () => {
					toast({
						title: "Manager updated",
						description: "The account manager has been updated",
						variant: "success",
					});
				},
				onError: () => {
					toast({
						title: "Manager not updated",
						description: "The account manager failed to update",
						variant: "error",
					});
					utils.agency.getManager.invalidate();
				},
			},
		);
	};

	const { toast } = useToast();
	const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!creator) {
			return;
		}

		const file = e.target.files?.[0];

		if (file) {
			if (file.size > 10_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 10MB",
				});
				e.target.value = "";
				return;
			}

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});

			updateAgencyCreatorProfilePicture.mutate(
				{
					userUuid: creator.user.uuid,
					uploadUuid: upload.uuid,
				},
				{
					onSuccess: () => {
						utils.agency.getCreatorsPreview.invalidate();
						utils.agency.getCreatorDetails.invalidate();
					},
				},
			);
		}
	};

	return (
		<div className="flex h-full w-full max-w-[640px] flex-col pb-8">
			<div className="flex flex-col gap-8">
				<p className="text-button-md font-repro">Personal details</p>
				<div className="flex w-full flex-col items-center justify-center gap-8 pb-8 pt-4">
					{creator.user.profilePicture ? (
						<div
							className="relative cursor-pointer"
							onClick={() => uploadRef.current?.click()}
						>
							<img
								className="shadow-40 h-24 w-24 rounded-full object-cover object-center"
								alt={`${creator.user.firstName}'s profile picture`}
								src={creator.user.profilePicture.url}
							/>
							<div className="bg-surface-hover-1 border-stroke-tertiary absolute bottom-0 right-0 flex h-8 w-8 items-center justify-center rounded-full border backdrop-blur-md transition-all disabled:cursor-not-allowed disabled:opacity-40">
								<PencilSimple />
							</div>
						</div>
					) : (
						<div
							className="bg-surface-tertiary flex h-24 w-24 cursor-pointer items-center justify-center rounded-full"
							onClick={() => uploadRef.current?.click()}
						>
							<Plus />
						</div>
					)}
					<input
						className="hidden"
						type="file"
						ref={uploadRef}
						onChange={onUpload}
					/>
				</div>
				<FormProvider {...updateAgencyUserForm}>
					<form className="flex flex-col gap-6">
						<div className="flex flex-col gap-6">
							<div className="flex items-center gap-3">
								<ZodInput
									name="firstName"
									label="First Name"
									placeholder="Add first name"
								/>
								<ZodInput
									name="lastName"
									label="Last Name"
									placeholder="Add last name"
								/>
							</div>

							<ZodInput
								name="email"
								label="Email Address"
								placeholder="Add email"
							/>

							<ZodInput
								className="w-full"
								name="username"
								label="July Username"
								placeholder="username"
								leadingIcon={() => (
									<p className="text-paragraph-md text-text-secondary">
										july.bio/
									</p>
								)}
							/>

							<ZodInput
								name="displayName"
								label="Display name (Optional)"
								placeholder="Add display name"
								description="Use if talent doesn't go by their actual name on social media."
							/>

							<LocationPicker
								label="location"
								setSelectedPlace={setSelectedLocation}
								value={selectedLocation}
							/>

							<ZodInput
								name="pronouns"
								label="Pronouns"
								placeholder="Add pronouns"
							/>

							<ZodDropdownInput
								name="currency"
								label="Currency"
								placeholder="Select a currency"
							>
								{CURRENCY_LIST.map((currency, i) => {
									return (
										<DropdownInput.Item key={i} value={currency.code}>
											<div className="flex flex-row items-center gap-2">
												{`${currency.name} (${currency.code})`}
												{currency.code === agencyProfile?.currency ? (
													<Tag text="Default currency" color="pink" />
												) : null}
											</div>
										</DropdownInput.Item>
									);
								})}
							</ZodDropdownInput>
						</div>
					</form>
				</FormProvider>
				<Button
					onClick={(e) => {
						e.preventDefault();
						updateAgencyUserForm.onSubmit();
					}}
					disabled={
						!updateAgencyUserForm.formState.isDirty &&
						selectedLocation === creator.user.location &&
						fields.currency === creator.currency
					}
				>
					Save changes
				</Button>

				<div className="bg-stroke-tertiary h-px w-full" />

				<Input
					label="Manager"
					placeholder="Search managers"
					variant="dropdown"
					value={selectedManagerEmail}
					options={(members ?? []).map((member) => {
						return {
							display: member.email ?? "",
							value: member.uuid,
						};
					})}
					onOptionSelected={(option) => updateCreatorManager(option)}
				/>

				<div className="bg-stroke-tertiary h-px w-full" />

				<div className="bg-stroke-tertiary flex flex-col gap-2 rounded-2xl p-4">
					<div className="flex items-center gap-2">
						<ToggleSwitch
							label="Exclusivity"
							toggle={isExclusive}
							onToggle={() => {
								updateAgencyCreatorExclusivity.mutate({
									creatorUuid: creator.uuid,
									isExclusive: !isExclusive,
								});
								setIsExclusive(!isExclusive);
							}}
						/>
						<Tooltip
							tooltip="Changing this setting will change the monthly cost for this creator. View full pricing details at withjuly.com/pricing."
							icon={Info}
						/>
					</div>
					<p className="text-text-secondary font-repro text-paragraph-md">
						If disabled, a ‘Non-Exclusive’ badge will be shown on all assets.
					</p>
				</div>

				<div className="bg-stroke-tertiary h-px w-full" />
				<p className="text-button-md font-repro">Danger zone</p>
				<Button onClick={() => setIsRemoveModalOpen(true)} variant="danger">
					Remove from roster
				</Button>
				{isRemoveModalOpen ? (
					<ConfirmRemoveModal
						creator={creator}
						isRemoveModalOpen={isRemoveModalOpen}
						setIsRemoveModalOpen={setIsRemoveModalOpen}
						onRemove={onRemove}
					/>
				) : null}
			</div>
		</div>
	);
};

interface ConfirmRemoveModalProps {
	creator: AgencyCreator;
	isRemoveModalOpen: boolean;
	setIsRemoveModalOpen: (open: boolean) => void;
	onRemove: () => void;
}

const ConfirmRemoveModal: React.FC<ConfirmRemoveModalProps> = ({
	creator,
	isRemoveModalOpen,
	setIsRemoveModalOpen,
	onRemove,
}) => {
	return (
		<Modal.Root isOpen={isRemoveModalOpen} setIsOpen={setIsRemoveModalOpen}>
			<Modal.Header title={`Remove ${creator.user.firstName}`} />

			<Modal.Body>
				<div className="bg-surface-quaternary mb-6 flex w-full flex-row items-center gap-4 rounded-xl p-4">
					<CreatorProfilePicture creator={creator} />
					<p className="font-repro text-body-lg truncate text-left">
						{creator.user.firstName} {creator.user.lastName}
					</p>
				</div>
				<p className="text-paragraph-sm font-repro text-text-secondary">
					If {creator.user.firstName} is removed from your roster, you will lose
					access to their July account. To regain account access, you’ll need to
					contact a representative from July.
				</p>
			</Modal.Body>
			<Modal.Footer
				primaryLabel={`Remove ${creator.user.firstName}`}
				onPrimaryClicked={onRemove}
				secondaryLabel="Cancel"
				onSecondaryClicked={() => setIsRemoveModalOpen(false)}
				buttons="primary-secondary"
				layout="separated"
				variant="danger"
				isPrimaryDisabled={false}
			/>
		</Modal.Root>
	);
};

const ConfirmDisconnectModal = ({
	isOpen,
	setIsOpen,
	onDelete,
	username,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	onDelete: () => void;
	username: string;
}) => {
	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title="Disconnect this account?" />

			<Modal.Body>
				<p className="text-paragraph-sm text-text-tertiary font-repro">
					This will permanently remove {username} from July.
				</p>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Disconnect"
				onPrimaryClicked={onDelete}
				secondaryLabel="Cancel"
				onSecondaryClicked={() => setIsOpen(false)}
				buttons="primary-secondary"
				layout="separated"
				variant="danger"
				isPrimaryDisabled={false}
			/>
		</Modal.Root>
	);
};

const ContentCategoriesTab = ({
	creator,
	categories,
	setCategories,
}: {
	creator: AgencyCreator;
	categories: string[];
	setCategories: (categories: string[]) => void;
}) => {
	const { agencyProfile } = useAgency();
	const utils = trpc.useContext();
	const { toast } = useToast();

	// STATE
	const [searchCategory, setSearchCategory] = useState<string>("");
	const [showOptions, setShowOptions] = useState<boolean>(false);

	// TRPC
	const updateAgencyContentCategories =
		trpc.agency.updateCustomContentCategories.useMutation();
	const updateContentCategoriesForCreator =
		trpc.mediaKit.updateContentCategoriesForCreator.useMutation();

	// HELPERS
	const saveCreatorTags = (newContent: string[]) => {
		updateContentCategoriesForCreator.mutate(
			{
				tags: newContent,
				creatorProfileUuid: creator.uuid,
			},
			{
				onError: () => {
					utils.agency.getCreatorDetails.invalidate();
				},
			},
		);
	};

	const options = useMemo(() => {
		if (agencyProfile && agencyProfile.contentCategories) {
			return [...agencyProfile.contentCategories, ...CONTENT_OPTIONS].sort(
				(a, b) => (a.toLocaleLowerCase() > b.toLocaleLowerCase() ? 1 : -1),
			);
		}
		return CONTENT_OPTIONS.sort();
	}, [agencyProfile]);

	return (
		<div className="flex h-full w-full flex-col items-center justify-center">
			<div className="flex h-full w-full max-w-[640px] flex-col gap-6 px-8">
				<div className="flex flex-col gap-1">
					<p className="text-button-md font-repro">Content Categories</p>
					<p className="text-paragraph-sm font-repro text-text-secondary">
						The type of content {creator.user.firstName} creates. Shown on Media
						Kit, Roster and Decks.
					</p>
				</div>
				<div className="flex flex-col gap-3">
					<SearchCategoryInput
						value={searchCategory}
						onChange={(e) => setSearchCategory(e.target.value)}
						placeholder="Search categories (Fashion, Fitness, etc.)"
						options={options}
						onOptionSelected={(value) => {
							const newContent = [...categories, value];
							setCategories(newContent);
							saveCreatorTags(newContent);
							setSearchCategory("");
						}}
						leadingIcon={MagnifyingGlass}
						customOptions={
							agencyProfile ? agencyProfile.contentCategories : undefined
						}
						selectedOptions={categories}
						onCreateClicked={(value) => {
							const newContent = [...categories, value];
							saveCreatorTags(newContent);
							setCategories(newContent);
							if (agencyProfile) {
								updateAgencyContentCategories.mutate(
									{
										newCategories: [...agencyProfile.contentCategories, value],
									},
									{
										onSuccess: () => {
											toast({
												title: "Content Category created",
												description:
													"Custom content categories can be managed from Agency Settings.",
												variant: "success",
											});
											setSearchCategory("");
											utils.agency.get.invalidate();
										},
									},
								);
							}
						}}
					/>

					<div className="flex flex-wrap gap-1">
						{categories.filter((category) => category.length > 0).length ===
						0 ? (
							<div className="flex h-[28px] items-center">
								<p className="font-repro text-paragraph-md text-text-placeholder">
									No categories added
								</p>
							</div>
						) : (
							categories
								.filter((category) => category.length > 0)
								.map((tag, i) => {
									return (
										<div
											key={`${tag}-${i}`}
											className="my-1 flex h-5 items-center"
										>
											<div className="bg-sky-alpha-2 text-paragraph-sm text-brand font-repro flex h-[28px] items-center justify-center gap-1 rounded-full px-[10px]">
												{tag}
												<button
													onClick={(e) => {
														e.preventDefault();
														const newContent = [...categories].filter(
															(t) => t !== tag,
														);
														saveCreatorTags(newContent);
														setCategories(newContent);
													}}
												>
													<X className="h-[14px] w-[14px]" />
												</button>
											</div>
										</div>
									);
								})
						)}
					</div>
				</div>
				<div className="flex flex-col gap-4">
					<Button
						variant="blank"
						onClick={() => setShowOptions(!showOptions)}
						className="w-[168px]"
						trailingIcon={showOptions ? CaretUp : CaretDown}
					>
						{showOptions ? "Hide" : "Show"} all options
					</Button>
					<div className="flex flex-col">
						<ScrollArea className="max-h-[calc(100vh-380px)]">
							{showOptions ? (
								<ToggleGroupSelect
									className="flex flex-col flex-nowrap gap-3"
									value={categories}
									setValue={(newContent) => {
										saveCreatorTags(newContent);
										setCategories(newContent);
									}}
								>
									{options.map((option) => {
										return (
											<ToggleGroupSelectItem
												key={option}
												value={option}
												className="flex h-6 cursor-pointer items-center gap-3 border-0"
											>
												<p className="font-repro text-paragraph-md text-text-secondary">
													{option}
												</p>
												{agencyProfile?.contentCategories.includes(option) ? (
													<Tag text="Custom" color="pink" />
												) : null}
											</ToggleGroupSelectItem>
										);
									})}
								</ToggleGroupSelect>
							) : null}
						</ScrollArea>
					</div>
				</div>
			</div>
		</div>
	);
};

interface ToggleGroupSelectItemProps {
	children: React.ReactNode;
	value: string;
	disabled?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

const ToggleGroupSelectItem: React.FC<ToggleGroupSelectItemProps> = ({
	children,
	value,
	disabled,
	className,
	style,
}) => {
	const itemRef = useRef<HTMLButtonElement | null>(null);

	return (
		<Item
			disabled={disabled}
			value={value}
			className={cx("group", className)}
			style={style}
			ref={itemRef}
		>
			<div
				className={cx(
					"border-stroke-primary rounded-solis-sm flex h-4 w-4 items-center justify-center border transition-colors",
					"group-data-[state=on]:border-brand group-data-[state=on]:bg-brand",
				)}
			>
				<Check color="#0E1316" width="10px" height="10px" />
			</div>

			{children}
		</Item>
	);
};

const SurveyResponsesTab = ({ creator }: { creator: AgencyCreator }) => {
	const { agencyProfile } = useAgency();
	const { user } = useAuth();
	const router = useRouter();
	const { data: onboardingFormInfo, isLoading: isLoadingFormInfo } =
		trpc.agency.getCreatorOnboardingResponses.useQuery(creator.uuid);

	const agencyMembership = useMemo(() => {
		if (!user || !agencyProfile) {
			return undefined;
		}
		return user.agencies.find((agency) => {
			return agency.agency.uuid === agencyProfile.uuid;
		});
	}, [user, agencyProfile]);

	if (isLoadingFormInfo) {
		return (
			<div className="flex h-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (
		agencyProfile?.onboardingForm &&
		agencyProfile?.onboardingForm.length === 0
	) {
		return (
			<div className="flex h-full w-[480px] flex-col items-center justify-center gap-1">
				<div className="mb-24 flex flex-col items-center justify-center gap-6">
					<FileDashed className="text-brand h-8 w-8" />
					<div className="flex flex-col items-center justify-center gap-3">
						<p className="text-header-lg font-repro">No onboarding survey</p>
						<p className="font-repro text-paragraph-md text-text-secondary text-center">
							July makes it easy to collect important information from your
							talent, like deal preferences and location.
						</p>
					</div>
					{agencyMembership?.role === "owner" ? (
						<Button
							variant="secondary"
							onClick={() =>
								router.push(`/agency/settings?openPage=onboard-survey`)
							}
						>
							Create a survey
						</Button>
					) : null}
				</div>
			</div>
		);
	}

	return (
		<ScrollArea className="flex h-full flex-col overflow-y-scroll">
			<div className="flex h-full w-full items-center justify-center">
				<div className="flex h-full w-full flex-col items-center justify-center">
					<div className="flex h-full w-full max-w-[640px] flex-col px-8 pb-8 pt-16">
						<div className="flex flex-col gap-8">
							{onboardingFormInfo &&
							onboardingFormInfo.writtenResponses.length !== 0
								? onboardingFormInfo.writtenResponses.map((question) => {
										return (
											<>
												<div
													key={question.questionUuid}
													className="flex flex-col gap-4"
												>
													<p className="text-button-md font-repro">
														{question.question}
													</p>
													{question.answer.trim().length > 0 ? (
														<p className="font-repro text-paragraph-sm">
															{question.answer}
														</p>
													) : (
														<p className="text-text-placeholder text-paragraph-sm font-repro">
															No response
														</p>
													)}
												</div>
												<div className="bg-stroke-tertiary h-px w-full" />
											</>
										);
									})
								: null}
							{agencyProfile?.onboardingForm &&
							agencyProfile?.onboardingForm.length !== 0
								? agencyProfile?.onboardingForm.map((section) => {
										return (
											<>
												<div
													key={section.uuid}
													className="border-b-stroke-tertiary flex flex-col gap-4 border-b pb-8 last:border-none"
												>
													<p className="text-button-md font-repro">
														{section.question}
													</p>
													<div className="flex flex-col gap-3">
														{section.options.map((option) => {
															if (
																onboardingFormInfo?.castingTags.some(
																	(tag) => tag === option.value,
																)
															) {
																return (
																	<div
																		key={option.uuid}
																		className="flex items-center gap-2"
																	>
																		<Check className="h-4 w-4" />{" "}
																		<p className="font-repro text-paragraph-sm">
																			{option.label}
																		</p>
																	</div>
																);
															} else {
																return (
																	<p
																		key={option.uuid}
																		className="text-text-placeholder font-repro text-paragraph-sm pl-6"
																	>
																		{option.label}
																	</p>
																);
															}
														})}
													</div>
												</div>
											</>
										);
									})
								: null}
						</div>
					</div>
				</div>
			</div>
		</ScrollArea>
	);
};
