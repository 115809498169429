import { AgencyBillingError, Platform } from "@withjuly/fabric";
import {
	Check,
	Instagram,
	Tiktok,
	Youtube,
	InstagramNoAuth,
	TiktokNoAuth,
	YoutubeNoAuth,
	YoutubeNoAuthWarning,
	InstagramNoAuthWarning,
	TiktokNoAuthWarning,
	InstagramNoAuthPending,
	TiktokNoAuthPending,
	YoutubeNoAuthPending,
	Facebook,
	Twitch,
	TwitchNoAuthPending,
} from "@withjuly/julycons";
import {
	User,
	UserPlus,
	ChartLine,
	LinkSimple,
	ListChecks,
	ArrowUp,
	ChartBar,
	ArrowDown,
	Copy,
	Bank,
	Warning,
} from "@withjuly/julycons/bold";
import { CaretDown } from "@withjuly/julycons/fill";
import { Loader } from "@withjuly/solis";
import {
	Button,
	Input,
	Tag,
	Table,
	DropdownMenu,
	TagProps,
	IconButton,
	Tooltip,
	useToast,
	Label,
	AlertBanner,
} from "@withjuly/solisv2";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";
import {
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { InviteTalentModal } from "~/components/Agency/InviteTalentModal";
import { formatNumber } from "~/components/Mediakits/utils";
import { RouterOutput, trpc } from "~/components/Utility/trpc";
import { AgencyLayout } from "~/layout/AgencyLayout";
import { AgencyAppLayout } from "~/layout/AgencyAppLayout";
import { NextPageWithLayout } from "~/utils/next";
import { getBioDomain, getFrontendDomain } from "~/utils/urls";
import { cx } from "@withjuly/frontend-common";
import { CreatorDetailsModal } from "~/components/Agency/TalentDetails/CreatorDetailsModal";
import { useDebunceCallback } from "~/utils/hooks/debounce";
import { useInView } from "react-intersection-observer";
import { AccountSetUpModal } from "~/components/Agency/AccountSetUpModal";
import { useAgency } from "~/utils/context/agency";
import { AddCreatorEmailModal } from "~/components/Agency/AddCreatorEmailModal";
import Head from "next/head";
import { JsonRecord } from "posthog-js";

type StatPlatform = "All platforms" | "Instagram" | "TikTok" | "YouTube";

interface CheckState {
	checked: boolean;
	auth: boolean;
	page: Page;
}
type Page = "no-auth" | "non-exclusive" | undefined;

const Agency: NextPageWithLayout = () => {
	const { ref, inView } = useInView({
		threshold: 0.5,
	});
	const creatorSearchInputRef = useRef<HTMLInputElement>(null);
	const setSearchCallback = useDebunceCallback(undefined, 500);
	const STAT_PLATFORMS: StatPlatform[] = [
		"All platforms",
		"Instagram",
		"TikTok",
		"YouTube",
	];
	const { agencyProfile } = useAgency();
	const isAccountSetUpEnabled = useFeatureFlagEnabled(
		"in-product-account-setup",
	);
	const alertBannerPayload = useFeatureFlagPayload("dashboard-alert-banner");

	// STATE
	const [isInviting, setIsInviting] = useState(false);
	const [isAlreadyChecked, setIsAlreadyChecked] = useState<CheckState>({
		checked: false,
		auth: true,
		page: undefined,
	});
	const [onboardModal, setOnboardModal] = useState<boolean>(false);

	const [searchInput, setSearchInput] = useState(() => "");
	const [statPlatform, setStatPlatform] =
		useState<StatPlatform>("All platforms");
	const [orderBy, setOrderBy] = useState<
		["name" | "following", "asc" | "desc"] | null
	>(["name", "asc"]);
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	const [searchCreators, setSearchCreators] = useState<
		RouterOutput["agency"]["searchTalent"]
	>([]);

	// TRPC
	const { data: agencyStats, isLoading } = trpc.agency.getRosterStats.useQuery(
		undefined,
		{
			trpc: {
				context: {
					skipBatch: true,
				},
			},
		},
	);

	const { isLoading: searching } = trpc.agency.searchTalent.useQuery(
		searchInput,
		{
			enabled: searchInput !== "",
			onSuccess: (data) => {
				setSearchCreators(data);
			},
		},
	);

	const {
		data: talentData,
		isLoading: areCreatorsLoading,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage,
	} = trpc.agency.getCreatorsPreview.useInfiniteQuery(
		{
			limit: 20,
		},
		{
			trpc: {
				context: {
					skipBatch: true,
				},
			},
			// Data will remain fresh for 20min and query will not refetch
			staleTime: 20 * 60 * 1000,
			refetchOnWindowFocus: false,
			getNextPageParam: (lastPage) => {
				const nextOffset = lastPage.offset + lastPage.size;
				if (lastPage.total && nextOffset >= lastPage.total) {
					// Returning undefined means we don't have any more pages
					return undefined;
				}
				return nextOffset;
			},
			onSuccess: (data) => {
				if (data.pages) {
					setCreatorsPreview(data.pages.flatMap(({ creators }) => creators));
				}
			},
		},
	);

	const [creatorsPreview, setCreatorsPreview] = useState<
		RouterOutput["agency"]["getCreatorsPreview"]["creators"]
	>(talentData ? talentData.pages.flatMap(({ creators }) => creators) : []);

	// FUNCTIONS
	useEffect(() => {
		if (inView && !isFetchingNextPage) {
			fetchNextPage();
		}
	}, [inView, fetchNextPage, isFetchingNextPage]);

	useEffect(() => {
		if (timeoutId) {
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [timeoutId]);

	useEffect(() => {
		if (
			isAccountSetUpEnabled &&
			agencyProfile &&
			agencyProfile.hasCompletedSetUp === false
		) {
			setOnboardModal(true);
		}
	}, [agencyProfile, isAccountSetUpEnabled]);

	const onSearchInputChange = useCallback(
		(searchText: string) => {
			setSearchCallback(async () => {
				setSearchInput(searchText);
				if (searchText === "") {
					clearSearch();
				}
			});
		},
		[setSearchCallback],
	);

	const clearSearch = () => {
		setSearchInput(() => "");
		if (creatorSearchInputRef.current) {
			creatorSearchInputRef.current.value = "";
		}
		setSearchCreators([]);
	};

	const setTimer = () => {
		const timeoutId = setTimeout(() => {
			setIsInviting(true);
		}, 1000);
		setTimeoutId(timeoutId);
	};

	const getBody = useMemo(() => {
		if (areCreatorsLoading) {
			return (
				<>
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
					<SkeletonRow />
				</>
			);
		} else if (searchInput !== "") {
			if (searching) {
				return (
					<div className="flex h-[calc(100vh-415px)] flex-col items-center justify-center">
						<Loader />
					</div>
				);
			} else if (!searching && searchCreators.length === 0) {
				return (
					<div className="flex h-[calc(100vh-415px)] flex-col items-center justify-center">
						<div className="bg-sky-alpha-4 mb-4 w-fit rounded-[8px] p-2">
							<User className="text-brand h-5 w-5" />
						</div>
						<div className="mb-6 flex flex-col items-center justify-center gap-[2px]">
							<p className="text-header-lg font-repro">No Creators</p>
							<p className="text-text-tertiary text-paragraph-sm font-repro">
								No creators match that input
							</p>
						</div>
						<Button size="sm" variant="outline" onClick={clearSearch}>
							Clear Search
						</Button>
						{isInviting ? (
							<InviteTalentModal
								isOpen={isInviting}
								setIsOpen={setIsInviting}
								setTimer={setTimer}
								alreadyChecked={isAlreadyChecked}
								continueChecked={setIsAlreadyChecked}
							/>
						) : null}
					</div>
				);
			} else {
				return searchCreators.map((creator) => (
					<CreatorRow
						key={creator.uuid}
						creator={creator}
						isTwitchConnected={
							agencyStats?.talentInfo.isTwitchConnected ?? true
						}
						isFacebookConnected={
							agencyStats?.talentInfo.isFacebookConnected ?? true
						}
					/>
				));
			}
		} else {
			return (
				<>
					{creatorsPreview.map((creator) => (
						<CreatorRow
							key={creator.uuid}
							creator={creator}
							isTwitchConnected={
								agencyStats?.talentInfo.isTwitchConnected ?? true
							}
							isFacebookConnected={
								agencyStats?.talentInfo.isFacebookConnected ?? true
							}
						/>
					))}
					{hasNextPage ? (
						<div ref={ref}>
							<SkeletonRow />
							<SkeletonRow />
							<SkeletonRow />
							<SkeletonRow />
						</div>
					) : null}
				</>
			);
		}
	}, [
		areCreatorsLoading,
		searchInput,
		searching,
		searchCreators,
		isInviting,
		isAlreadyChecked,
		agencyStats?.talentInfo.isTwitchConnected,
		agencyStats?.talentInfo.isFacebookConnected,
		creatorsPreview,
		hasNextPage,
		ref,
	]);

	return (
		<>
			<Head>
				<title>Talent • July</title>
			</Head>

			<div className="flex h-screen flex-col overflow-hidden pb-8">
				<div className="flex w-full flex-col items-center justify-between gap-6 p-8">
					{alertBannerPayload && typeof alertBannerPayload === "object" ? (
						<AlertBanner
							title={(alertBannerPayload as JsonRecord).title?.toString() ?? ""}
							description={
								(alertBannerPayload as JsonRecord).description?.toString() ?? ""
							}
							variant="warning"
							icon={Warning}
						/>
					) : null}

					<div className="flex w-full items-center justify-between">
						<h1 className="text-header-2xl font-repro">Talent</h1>
						<div className="flex flex-row items-center gap-4">
							<Button
								variant="primary"
								size="md"
								onClick={() => setIsInviting(true)}
								leadingIcon={UserPlus}
								className="whitespace-nowrap"
							>
								Invite Talent
							</Button>
							{isInviting ? (
								<InviteTalentModal
									isOpen={isInviting}
									setIsOpen={setIsInviting}
									setTimer={setTimer}
									alreadyChecked={isAlreadyChecked}
									continueChecked={setIsAlreadyChecked}
								/>
							) : null}
							{isAccountSetUpEnabled && onboardModal ? (
								<AccountSetUpModal
									isOpen={onboardModal}
									setIsOpen={setOnboardModal}
								/>
							) : null}
						</div>
					</div>
				</div>

				<div className="flex w-full flex-col px-8">
					<div className="bg-surface-secondary border-stroke-secondary mb-8 flex h-[147px] w-full justify-between rounded-2xl border px-6">
						<div className="flex py-6">
							<div className="flex w-[256px] flex-col gap-1">
								<p className="font-repro text-overline-xs text-text-secondary flex items-center gap-1">
									CREATORS
								</p>

								{!isLoading ? (
									<p className="text-header-4xl font-repro">
										{agencyStats?.talentInfo.totalCreators}
									</p>
								) : (
									<div className="bg-stroke-tertiary mt-2 h-10 w-[77px] animate-pulse rounded-xl"></div>
								)}
							</div>
							<div className="flex flex-col gap-1">
								<p className="font-repro text-overline-xs text-text-secondary flex items-center gap-1">
									TOTAL AUDIENCE
								</p>
								{!isLoading ? (
									<p className="text-header-4xl font-repro">
										{formatNumber(agencyStats?.talentInfo.totalAudience ?? 0)}
									</p>
								) : (
									<div className="bg-stroke-tertiary mt-2 h-10 w-[77px] animate-pulse rounded-xl"></div>
								)}{" "}
							</div>
						</div>
						<div className="border-stroke-secondary flex h-full w-[480px] flex-col gap-4 border-l py-6 pl-6">
							<div className="flex gap-1">
								<ChartBar className="h-4 w-4" />
								<Label size="xs" variant="overline" color="secondary">
									Roster stats on
								</Label>
								<DropdownMenu.Root>
									<DropdownMenu.Trigger variant="custom">
										<p className="text-overline-xs text-text-primary font-repro flex cursor-pointer items-center gap-1">
											{statPlatform.toUpperCase()} <CaretDown />
										</p>
									</DropdownMenu.Trigger>
									<DropdownMenu.Content>
										{STAT_PLATFORMS.map((platform) => (
											<DropdownMenu.Item
												key={platform}
												onSelect={() => setStatPlatform(platform)}
											>
												{platform}
											</DropdownMenu.Item>
										))}
									</DropdownMenu.Content>
								</DropdownMenu.Root>
							</div>
							{isLoading ? (
								<div className="flex flex-col gap-2">
									<div className="bg-stroke-tertiary h-4 w-full animate-pulse rounded-xl"></div>
									<div className="bg-stroke-tertiary h-4 w-full animate-pulse rounded-xl"></div>
									<div className="bg-stroke-tertiary h-4 w-full animate-pulse rounded-xl"></div>
								</div>
							) : (
								<div>
									{agencyStats?.stats
										?.find((stats) => stats.platform === statPlatform)
										?.stats.map((stat) => {
											return (
												<div key={stat.name} className="flex justify-between">
													<Label
														size="xs"
														variant="overline"
														color="secondary"
														tooltip={stat.tooltip}
													>
														{stat.name}
													</Label>
													<div className="text-button-md font-repro">
														{stat.name.toLowerCase() ===
														"average engagement rate"
															? `${formatNumber(stat.value)}%`
															: formatNumber(stat.value)}
													</div>
												</div>
											);
										})}
								</div>
							)}
						</div>
					</div>

					<div className="bg-surface-secondary border-stroke-secondary h-[calc(100vh-286px-32px)] overflow-y-scroll rounded-2xl border">
						{!areCreatorsLoading && creatorsPreview.length === 0 ? (
							<div className="h-full w-full">
								<div
									className="sticky top-0 z-10 h-[56px] w-full p-3"
									style={{
										background:
											"linear-gradient(270deg, #05A1C4 0%, #13587D 100%)",
									}}
								>
									<div className="flex items-center justify-center gap-4">
										<p className="text-button-sm font-repro">
											Want help importing your talent to July?
										</p>
										<a
											href="https://calendly.com/margot-beard/july-agency-onboarding"
											target="_blank"
											className="bg-surface-hover-1 text-button-sm font-repro flex h-8 items-center rounded-full px-3"
										>
											Schedule onboarding call
										</a>
									</div>
								</div>
								<div className="flex h-[calc(100vh-286px-32px-60px)]  w-full flex-col items-center justify-center">
									<div className="mb-24 flex flex-col items-center justify-center gap-6">
										<UserPlus className="text-brand h-8 w-8" />
										<div className="flex flex-col items-center justify-center gap-1">
											<p className="text-header-lg font-repro">Roster empty</p>
											<p className="font-repro text-paragraph-md text-text-secondary">
												Invite your talent to get started.
											</p>
										</div>
										<Button
											variant="primary"
											size="sm"
											onClick={() => setIsInviting(true)}
										>
											Invite Talent
										</Button>
									</div>
								</div>
							</div>
						) : (
							<Table.Root>
								<Table.Head className="bg-surface-secondary sticky top-0 z-10">
									<div className="flex items-center justify-between py-4">
										<p className="text-header-xl font-repro px-2">Roster</p>
										<div className="flex items-center gap-3">
											<Input
												name="search"
												onChange={(e) => onSearchInputChange(e.target.value)}
												placeholder="Search talent"
												ref={creatorSearchInputRef}
												variant="search"
												rounded
												size="md"
												className="w-[352px]"
												onClear={clearSearch}
											/>
										</div>
									</div>
									<Table.Row className="max-h-[30px]">
										<Table.Cell
											variant="th"
											selected={true}
											state={
												orderBy?.[0] === "name" && orderBy?.[1] === "desc"
													? "reverse"
													: "default"
											}
											onClick={() => {
												setOrderBy((prev) => {
													if (!prev || prev[0] === "following") {
														return ["name", "asc"];
													} else {
														if (prev[1] === "asc") {
															return ["name", "desc"];
														} else {
															return ["name", "asc"];
														}
													}
												});
											}}
											className="min-w-fit"
										>
											NAME
										</Table.Cell>
										<Table.Cell
											variant="th"
											className="relative min-w-fit overflow-hidden text-nowrap"
										>
											TOTAL AUDIENCE
										</Table.Cell>
										<Table.Cell
											variant="th"
											className="relative overflow-hidden text-nowrap"
										>
											AUDIENCE GROWTH (PAST 30D)
										</Table.Cell>
										<Table.Cell
											variant="th"
											className="relative min-w-fit overflow-hidden text-nowrap"
										>
											SOCIALS
										</Table.Cell>
										<Table.Cell variant="th"></Table.Cell>
									</Table.Row>
								</Table.Head>

								<Table.Body>
									<div className="overflow-y-auto">{getBody}</div>
								</Table.Body>
							</Table.Root>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const SkeletonRow: React.FC = () => {
	return (
		<Table.Row>
			<Table.Cell>
				<div className="flex w-[120px] items-center gap-2">
					<div className="bg-surface-quaternary h-6 w-6 min-w-6 animate-pulse rounded-full"></div>
					<div className="bg-surface-quaternary h-4 w-full animate-pulse rounded-lg"></div>
				</div>
			</Table.Cell>
			<Table.Cell>
				<div className="flex gap-2">
					<div className="bg-surface-quaternary h-1 w-3 animate-pulse rounded-lg"></div>
				</div>
			</Table.Cell>
			<Table.Cell>
				<div className="flex gap-2">
					<div className="bg-surface-quaternary h-[28px] w-11 animate-pulse rounded-full"></div>
				</div>
			</Table.Cell>
			<Table.Cell>
				<div className="flex gap-1">
					<div className="bg-surface-quaternary h-6 w-6 min-w-6 animate-pulse rounded-full"></div>
					<div className="bg-surface-quaternary h-6 w-6 min-w-6 animate-pulse rounded-full"></div>
					<div className="bg-surface-quaternary h-6 w-6 min-w-6 animate-pulse rounded-full"></div>
				</div>
			</Table.Cell>
			<Table.Cell align="right" className="min-w-fit">
				<div className="flex items-center gap-4">
					<div className="bg-surface-quaternary h-8 w-8 min-w-8 animate-pulse rounded-full"></div>
					<div className="flex items-center gap-6">
						<div className="flex items-center gap-2">
							<div className="bg-surface-quaternary h-8 w-8 min-w-8 animate-pulse rounded-full"></div>
							<div className="bg-surface-quaternary h-8 w-8 min-w-8 animate-pulse rounded-full"></div>
						</div>
						<div className="bg-surface-quaternary h-8 w-8 min-w-8 animate-pulse rounded-full"></div>
					</div>
				</div>
			</Table.Cell>
		</Table.Row>
	);
};

interface CreatorRowProps {
	creator: RouterOutput["agency"]["getCreatorsPreview"]["creators"][number];
	isTwitchConnected: boolean;
	isFacebookConnected: boolean;
}

const CreatorRow: React.FC<CreatorRowProps> = ({
	creator,
	isTwitchConnected,
	isFacebookConnected,
}) => {
	const [isLinkClicked, setIsLinkClicked] = useState(() => false);
	const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(() => false);
	const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(() => false);
	const { toast } = useToast();
	const removeCreator = trpc.agency.removeCreatorFromAgency.useMutation();
	const sendConnectionEmail = trpc.agency.sendConnectionEmail.useMutation();
	const sendBankDetailsRequestEmail =
		trpc.agency.sendBankDetailsRequestEmail.useMutation();
	const utils = trpc.useContext();
	const hasCompletedSurvey = creator.hiddenTags.length > 0;
	const { agencyProfile } = useAgency();

	const sendBankDetailsRequest = useCallback(() => {
		sendBankDetailsRequestEmail.mutate(creator.uuid, {
			onSuccess: () => {
				toast({
					title: "Request sent",
					description: `July sent ${creator.user.firstName} an email asking them to connect their bank account.`,
					variant: "success",
				});
			},
		});
	}, [
		creator.user.firstName,
		creator.uuid,
		sendBankDetailsRequestEmail,
		toast,
	]);

	const isInvitePending = useMemo(() => {
		return creator.industries.length === 0;
	}, [creator.industries]);

	const name =
		creator.user.displayName ??
		`${creator.user.firstName} ${creator.user.lastName}`;

	const totalAudience = useMemo(() => {
		const audience = creator.connectedAccounts
			.filter(
				(acc) =>
					acc.platformProfile?.isActive &&
					!acc.platformProfile?.isPending &&
					!acc.platformProfile?.hasIssue,
			)
			.reduce(
				(audience, account) => (account.platformProfile?.size ?? 0) + audience,
				0,
			);

		if (audience === 0) {
			return "-";
		} else {
			return formatNumber(audience);
		}
	}, [creator.connectedAccounts]);

	const sendConnectionLink = useCallback(() => {
		sendConnectionEmail.mutate(creator.uuid, {
			onSuccess: () => {
				toast({
					title: "Invite link sent",
					description: `July sent an invite link to ${creator.user.displayName ?? `${creator.user.firstName} ${creator.user.lastName}`}`,
					variant: "success",
				});
			},
		});
	}, [
		creator.user.displayName,
		creator.user.firstName,
		creator.user.lastName,
		creator.uuid,
		sendConnectionEmail,
		toast,
	]);

	const isPlatformNoAuth = (platform: Platform) => {
		return (
			platform === Platform.INSTAGRAM_NOAUTH ||
			platform === Platform.TIKTOK_NOAUTH ||
			platform === Platform.YOUTUBE_NOAUTH
		);
	};

	const platformLinks = useMemo(() => {
		const instagramProfiles = creator?.connectedAccounts.filter(
			(account) =>
				account.platformProfile?.isActive &&
				(account.platformProfile?.platform === Platform.INSTAGRAM ||
					account.platformProfile?.platform === Platform.INSTAGRAM_NOAUTH),
		);
		const tikTokProfiles = creator?.connectedAccounts.filter(
			(account) =>
				account.platformProfile?.isActive &&
				(account.platformProfile?.platform === Platform.TIKTOK ||
					account.platformProfile?.platform === Platform.TIKTOK_NOAUTH),
		);
		const youTubeProfiles = creator?.connectedAccounts.filter(
			(account) =>
				account.platformProfile?.isActive &&
				(account.platformProfile?.platform === Platform.YOUTUBE ||
					account.platformProfile?.platform === Platform.YOUTUBE_NOAUTH),
		);

		const instagramProfile =
			instagramProfiles.find(
				(p) => p.platformProfile?.platform === Platform.INSTAGRAM_NOAUTH,
			) ?? instagramProfiles[0];

		const tikTokProfile =
			tikTokProfiles.find(
				(p) => p.platformProfile?.platform === Platform.TIKTOK_NOAUTH,
			) ?? tikTokProfiles[0];

		const youTubeProfile =
			youTubeProfiles.find(
				(p) => p.platformProfile?.platform === Platform.YOUTUBE_NOAUTH,
			) ?? youTubeProfiles[0];

		const faceBookProfile = creator?.connectedAccounts.filter(
			(account) =>
				account.platformProfile?.isActive &&
				account.platformProfile.platform === Platform.FACEBOOK,
		)[0];

		const twitchProfile = creator?.connectedAccounts.filter(
			(account) =>
				account.platformProfile?.isActive &&
				account.platformProfile.platform === Platform.TWITCH,
		)[0];

		return (
			<div className="flex items-center justify-center gap-[6px]">
				<PlatformConnection
					url={instagramProfile?.platformProfile?.profileUrl}
					platform={
						instagramProfile?.platformProfile?.platform ?? Platform.INSTAGRAM
					}
					sendConnectionLink={sendConnectionLink}
					hasIssue={instagramProfile?.platformProfile?.hasIssue}
					isConnected={!!instagramProfile}
					creatorUuid={creator.uuid}
					isAuth={
						!isPlatformNoAuth(
							instagramProfile?.platformProfile?.platform ?? Platform.INSTAGRAM,
						)
					}
					isPending={instagramProfile?.platformProfile?.isPending ?? true}
				/>
				<PlatformConnection
					url={tikTokProfile?.platformProfile?.profileUrl}
					platform={tikTokProfile?.platformProfile?.platform ?? Platform.TIKTOK}
					sendConnectionLink={sendConnectionLink}
					hasIssue={tikTokProfile?.platformProfile?.hasIssue}
					isConnected={!!tikTokProfile}
					creatorUuid={creator.uuid}
					isAuth={
						!isPlatformNoAuth(
							tikTokProfile?.platformProfile?.platform ?? Platform.TIKTOK,
						)
					}
					isPending={tikTokProfile?.platformProfile?.isPending ?? true}
				/>
				<PlatformConnection
					url={youTubeProfile?.platformProfile?.profileUrl}
					platform={
						youTubeProfile?.platformProfile?.platform ?? Platform.YOUTUBE
					}
					sendConnectionLink={sendConnectionLink}
					hasIssue={youTubeProfile?.platformProfile?.hasIssue}
					isConnected={!!youTubeProfile}
					creatorUuid={creator.uuid}
					isAuth={
						!isPlatformNoAuth(
							youTubeProfile?.platformProfile?.platform ?? Platform.YOUTUBE,
						)
					}
					isPending={youTubeProfile?.platformProfile?.isPending ?? true}
				/>
				{isFacebookConnected ? (
					<PlatformConnection
						url={faceBookProfile?.platformProfile?.profileUrl}
						platform={
							faceBookProfile?.platformProfile?.platform ?? Platform.FACEBOOK
						}
						sendConnectionLink={sendConnectionLink}
						hasIssue={faceBookProfile?.platformProfile?.hasIssue}
						isConnected={!!faceBookProfile}
						creatorUuid={creator.uuid}
						isAuth={true}
						isPending={false}
					/>
				) : null}
				{isTwitchConnected ? (
					<PlatformConnection
						url={twitchProfile?.platformProfile?.profileUrl}
						platform={
							twitchProfile?.platformProfile?.platform ?? Platform.TWITCH
						}
						sendConnectionLink={sendConnectionLink}
						hasIssue={twitchProfile?.platformProfile?.hasIssue}
						isConnected={!!twitchProfile}
						creatorUuid={creator.uuid}
						isAuth={true}
						isPending={twitchProfile?.platformProfile?.isPending ?? false}
					/>
				) : null}
			</div>
		);
	}, [
		isFacebookConnected,
		isTwitchConnected,
		creator?.connectedAccounts,
		creator.uuid,
		sendConnectionLink,
	]);

	const remove = () => {
		removeCreator.mutate(creator.uuid, {
			onSuccess: () => {
				toast({
					title: "Talent removed",
					description: `${creator.user.firstName} ${creator.user.lastName} has been removed from your roster`,
					variant: "neutral",
				});
				utils.agency.getCreatorsPreview.invalidate();
				utils.agency.get.invalidate();
			},
			onError: (e) => {
				const parsedError =
					AgencyBillingError.AgencyBillingErrorSchema.safeParse(e.data);

				if (parsedError.success) {
					const error = parsedError.data;
					toast({
						variant: "error",
						title: error.message,
						description: error.description,
					});
				} else {
					toast({
						variant: "error",
						title: "Error removing talent",
						description:
							"There was an error removing this talent from your roster. Please try again later.",
					});
				}
			},
		});
		setIsDetailsModalOpen(false);
	};

	const copyMediakitLink = () => {
		window.navigator.clipboard.writeText(
			`https://${getBioDomain()}/${creator.username ?? ""}`,
		);
		setIsLinkClicked(() => true);
		setTimeout(() => setIsLinkClicked(() => false), 1000);
	};

	const showCreatorDetails = true;

	let percentChangeString = "0%";
	let changeIcon = ArrowUp;
	let statusColor: TagProps["color"] = "graphite";

	if (creator.growth > 0) {
		percentChangeString = `${creator.growth.toFixed(1)}%`;
		changeIcon = ArrowUp;
		statusColor = "green";
	} else if (creator.growth < 0) {
		percentChangeString = `${-creator.growth.toFixed(1)}%`;
		changeIcon = ArrowDown;
		statusColor = "red";
	}

	return (
		<>
			<Table.Row onClick={() => setIsDetailsModalOpen(true)} className="group">
				<Table.Cell
					variant="account"
					src={creator.user.profilePicture?.url}
					className="relative overflow-hidden text-nowrap"
					alt={name}
					size="xs"
				>
					{name}
				</Table.Cell>
				<Table.Cell className="ml-1">
					{creator.isPending ? (
						<p className="text-paragraph-xs text-text-tertiary font-repro">
							Pending
						</p>
					) : (
						totalAudience
					)}
				</Table.Cell>
				<Table.Cell>
					{creator.isPending ? (
						<p className="text-paragraph-xs text-text-tertiary font-repro">
							Pending
						</p>
					) : (
						<Tag
							text={percentChangeString}
							size="lg"
							color={statusColor}
							leftIcon={creator.growth == 0 ? undefined : changeIcon}
						/>
					)}
				</Table.Cell>
				<Table.Cell variant="dropdown">{platformLinks}</Table.Cell>

				<Table.Cell variant="dropdown" align="right" className="min-w-fit">
					<div className="flex items-center gap-6">
						<Tooltip
							tooltip={`${creator.user.firstName} has ${hasCompletedSurvey ? "" : "not"}
									completed the onboarding form.`}
							icon={() => (
								<ListChecks
									className={
										hasCompletedSurvey
											? "text-text-primary"
											: "text-text-placeholder"
									}
								/>
							)}
						/>

						<div className="flex items-center gap-2">
							<IconButton
								onClick={(e) => {
									e.stopPropagation();
									copyMediakitLink();
								}}
								icon={() => {
									if (!isLinkClicked) return <LinkSimple />;
									else return <Check className="text-blue-500" />;
								}}
								size="sm"
								variant="secondary"
								tooltip="Copy media kit link"
							/>

							{showCreatorDetails ? (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										setIsDetailsModalOpen(true);
									}}
									disabled={
										isInvitePending || creator.connectedAccounts.length === 0
									}
									size="sm"
									variant="secondary"
									icon={() => (
										<ChartLine className="group-disabled:opacity-10" />
									)}
									tooltip="View audience analytics"
								/>
							) : null}
						</div>
						<DropdownMenu.Root>
							<DropdownMenu.Trigger size="sm" />
							<DropdownMenu.Content>
								<DropdownMenu.Item
									icon={LinkSimple}
									onClick={(e) => {
										e.stopPropagation();
										if (creator.user.email.length === 0) {
											setIsAddEmailModalOpen(true);
										} else {
											sendConnectionLink();
										}
									}}
								>
									Resend invite link
								</DropdownMenu.Item>
								<DropdownMenu.Item
									icon={Copy}
									onClick={(e) => {
										e.stopPropagation();
										window.navigator.clipboard.writeText(
											`https://${getFrontendDomain()}/join/${agencyProfile?.username}`,
										);
										toast({
											title: "Invite Link copied",
											description: `The invite link for ${creator.user.firstName} has been copied to your clipboard`,
											variant: "success",
										});
									}}
								>
									Copy invite link
								</DropdownMenu.Item>
								{agencyProfile?.isPaymentsEnabled &&
								agencyProfile?.paymentsApplicaionStatus === "ACCEPTED" ? (
									<DropdownMenu.Item
										icon={Bank}
										onClick={(e) => {
											e.stopPropagation();
											sendBankDetailsRequest();
										}}
									>
										Request bank info
									</DropdownMenu.Item>
								) : null}
							</DropdownMenu.Content>
						</DropdownMenu.Root>
					</div>
				</Table.Cell>
			</Table.Row>

			{isDetailsModalOpen && creator.uuid ? (
				<CreatorDetailsModal
					isOpen={isDetailsModalOpen}
					setIsOpen={setIsDetailsModalOpen}
					creatorUuid={creator.uuid}
					onRemove={remove}
				/>
			) : null}

			{isAddEmailModalOpen && creator.uuid ? (
				<AddCreatorEmailModal
					isOpen={isAddEmailModalOpen}
					setIsOpen={setIsAddEmailModalOpen}
					userUuid={creator.user.uuid}
					name={
						creator.user.displayName ??
						`${creator.user.firstName} ${creator.user.lastName}`
					}
					sendEmail={sendConnectionLink}
				/>
			) : null}
		</>
	);
};

interface PlatformConnectionProps {
	url?: string;
	platform: Platform;
	hasIssue?: boolean;
	sendConnectionLink: () => void;
	isConnected: boolean;
	creatorUuid: string;
	isAuth: boolean;
	isPending: boolean;
}

const PlatformConnection: React.FC<PlatformConnectionProps> = ({
	url,
	platform,
	hasIssue,
	isConnected,
	isAuth,
	isPending,
}) => {
	const isConnectionIssueEnabled = useFeatureFlagEnabled(
		"show-platform-issues",
	);

	const Icon = useMemo(() => {
		const IconInner: React.FC<{ className?: string }> = ({ className }) => {
			if (platform === Platform.INSTAGRAM) {
				return <Instagram className={className} />;
			} else if (platform === Platform.TIKTOK) {
				return <Tiktok className={className} />;
			} else if (platform === Platform.YOUTUBE) {
				return <Youtube className={className} />;
			} else if (platform === Platform.INSTAGRAM_NOAUTH) {
				if (hasIssue && isConnectionIssueEnabled) {
					return <InstagramNoAuthWarning />;
				} else if (isPending) {
					return <InstagramNoAuthPending />;
				} else {
					return <InstagramNoAuth />;
				}
			} else if (platform === Platform.TIKTOK_NOAUTH) {
				if (hasIssue && isConnectionIssueEnabled) {
					return <TiktokNoAuthWarning />;
				} else if (isPending) {
					return <TiktokNoAuthPending />;
				} else {
					return <TiktokNoAuth />;
				}
			} else if (platform === Platform.YOUTUBE_NOAUTH) {
				if (hasIssue && isConnectionIssueEnabled) {
					return <YoutubeNoAuthWarning />;
				} else if (isPending) {
					return <YoutubeNoAuthPending />;
				} else {
					return <YoutubeNoAuth />;
				}
			} else if (platform === Platform.FACEBOOK) {
				return <Facebook className={className} />;
			} else if (platform === Platform.TWITCH) {
				if (isPending) {
					return <TwitchNoAuthPending />;
				} else {
					return <Twitch className={className} />;
				}
			} else {
				return null;
			}
		};

		return IconInner;
	}, [platform, isConnectionIssueEnabled, hasIssue, isPending]);

	if (isAuth && hasIssue && isConnectionIssueEnabled) {
		return (
			<Tooltip
				icon={() => (
					<div className="flex h-[22px] w-[22px] items-center">
						<Icon className="text-text-warning mx-[2px]" />
					</div>
				)}
				tooltip="Connection to this account seems to be broken. Please send a reconnection link, or contact us for assistance."
			/>
		);
	} else if (!isConnected) {
		return (
			<div className="flex h-[22px] w-[22px] items-center">
				<Icon className="text-text-placeholder mx-[2px]" />
			</div>
		);
	} else if (
		(!isAuth && isPending && isConnected) ||
		(platform === Platform.TWITCH && isPending && isConnected)
	) {
		return (
			<Tooltip
				icon={() => <Icon />}
				tooltip="The connection to this account is pending. July is attempting to establish a connection."
			/>
		);
	} else {
		return (
			<a
				href={url ?? ""}
				target="_blank"
				rel="noreferrer"
				onClick={(e) => e.stopPropagation()}
				className="flex h-[22px] w-[22px] items-center"
			>
				<Icon className={cx(isAuth && "mx-[2px]")} />
			</a>
		);
	}
};

Agency.getLayout = (page: ReactElement) => {
	return (
		<AgencyAppLayout>
			<AgencyLayout>{page}</AgencyLayout>
		</AgencyAppLayout>
	);
};

export default Agency;
