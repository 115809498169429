import {
	CustomProfileDemographics,
	CustomProfileStat,
	nonNull,
	UpdateCustomProfileSchema,
	UpsertCustomProfileContentSchema,
	CustomProfileContent,
	SerializedCustomProfileStat,
	CustomProfileStatSchema,
} from "@withjuly/fabric";
import React, { useMemo, useRef, useState } from "react";
import { trpc } from "../../Utility/trpc";
import {
	IconButton,
	Button,
	Label,
	Modal,
	ToggleSwitch,
	useToast,
	DropdownInput,
	Tag,
	ScrollArea,
} from "@withjuly/solisv2";
import {
	Plus,
	PencilSimple,
	ArrowLeft,
	Trash,
	Upload,
	DeviceMobileSpeaker,
	Square,
	Rectangle,
	DotsSixVertical,
} from "@withjuly/julycons/bold";
import { useZodForm } from "~/utils/hooks/zod-form";
import { ZodInput } from "../../Input/ZodInput";
import { FormProvider, useFieldArray } from "react-hook-form";
import { z } from "zod";
import { ZodTextArea } from "../../Input/ZodTextArea";
import { AddCustomAudienceDemographic } from "./AddCustomAudienceDemographic";
import { ZodDropdownInput } from "../../Input/ZodDropdownInput";
import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	TouchSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
	SortableContext,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface CustomProfileEditorProps {
	onClickBack: (index: number) => void;
	profileUuid: string;
	creatorUuid: string;
	isActive: boolean;
}

type StatModalType =
	| { open: false }
	| {
			open: true;
			stat: SerializedCustomProfileStat;
			isNew: false;
	  }
	| { open: true; stat: undefined; isNew: true };

type AudienceModalType =
	| { open: false }
	| { open: true; metadata: CustomProfileDemographics; isNew: false }
	| { open: true; isNew: true; metadata: undefined };
type ContentModalType =
	| { open: false }
	| { open: true; content: CustomProfileContent; isNew: false }
	| { open: true; isNew: true; content: undefined };

export const CustomProfileEditor: React.FC<CustomProfileEditorProps> = ({
	onClickBack,
	profileUuid,
	creatorUuid,
	isActive,
}) => {
	const utils = trpc.useContext();
	const { data: profile, isLoading: isLoading } =
		trpc.agency.customProfiles.get.useQuery({
			uuid: profileUuid,
		});
	const updateCustomProfile = trpc.agency.customProfiles.update.useMutation();
	const deleteProfile =
		trpc.agency.customProfiles.deleteCustomProfile.useMutation();
	const updateCustomProfileActivation =
		trpc.agency.customProfiles.updateActivation.useMutation();
	const updateContentOrder =
		trpc.agency.customProfiles.updateContentOrder.useMutation();
	const updateStatsOrder =
		trpc.agency.customProfiles.updateStatsOrder.useMutation();

	const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
	const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
	const [isContentModalOpen, setIsContentModalOpen] =
		useState<ContentModalType>({ open: false });
	const [isAudienceModalOpen, setIsAudienceModalOpen] =
		useState<AudienceModalType>({ open: false });
	const [isStatModalOpen, setIsStatModalOpen] = useState<StatModalType>({
		open: false,
	});
	const [isProfileActive, setIsProfileActive] = useState(isActive);

	const form = useZodForm({
		schema: UpdateCustomProfileSchema,
		values: {
			uuid: profile?.uuid ?? "",
			title: profile?.title ?? "",
			username: profile?.username ?? "",
			accountUrl: profile?.accountUrl ?? "",
			metadata: {
				stats: profile?.metadata?.stats,
				demographics: profile?.metadata?.demographics,
			},
			content: profile?.content,
		},
		submit: async (data, { onFormError }) => {
			updateCustomProfile.mutateAsync(data, {
				onSuccess: () => {
					utils.agency.get.invalidate();
					utils.agency.customProfiles.get.invalidate();
				},
				onError: (error) => {
					onFormError(error);
				},
			});
		},
	});

	const statFieldArray = useFieldArray({
		control: form.control,
		name: "metadata.stats",
	});

	const statFields =
		statFieldArray.fields as unknown as SerializedCustomProfileStat[];

	const contentFieldArray = useFieldArray({
		control: form.control,
		name: "content",
	});

	const contentFields =
		contentFieldArray.fields as unknown as CustomProfileContent[];

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8,
			},
		}),
		useSensor(TouchSensor, {
			activationConstraint: {
				delay: 250,
				tolerance: 8,
			},
		}),
		useSensor(KeyboardSensor),
	);

	const onDragEndContent = async (event: DragEndEvent) => {
		const { active, over } = event;
		const activeIndex = contentFields.findIndex((f) => f.uuid === active.id);
		const overIndex = contentFields.findIndex((f) => f.uuid === over?.id);
		if (activeIndex !== -1 && overIndex !== -1) {
			const activeItem = contentFields.find((c) => c.uuid === active.id);
			const overItem = contentFields.find((c) => c.uuid === over?.id);
			if (activeItem && overItem) {
				contentFieldArray.move(activeIndex, overIndex);
				const newContent = contentFields.slice();
				newContent.splice(activeIndex, 1);
				newContent.splice(overIndex, 0, activeItem);
				if (profile?.content && profile?.content.length !== 0) {
					updateContentOrder.mutateAsync({
						content: newContent.map((c, i) => {
							return {
								uuid: c.uuid,
								order: i,
							};
						}),
					});
				}
			}
		}
	};

	const onDragEndStat = async (event: DragEndEvent) => {
		const { active, over } = event;

		const activeIndex = statFields.findIndex((f) => f.uuid === active.id);
		const activeEntry = statFields[activeIndex];

		const overIndex = statFields.findIndex((f) => f.uuid === over?.id);
		const overEntry = statFields[overIndex];

		if (!activeEntry || !overEntry) {
			return;
		}

		statFieldArray.move(activeIndex, overIndex);
		const newStats = statFields.slice();
		newStats.splice(activeIndex, 1);
		newStats.splice(overIndex, 0, activeEntry);
		if (profile?.content && profile?.content.length !== 0) {
			updateStatsOrder.mutateAsync({
				stats: newStats,
				uuid: profileUuid,
			});
		}
	};

	const hasDemographics = useMemo(() => {
		return (
			profile?.metadata?.demographics?.age ||
			profile?.metadata?.demographics?.country ||
			profile?.metadata?.demographics?.gender
		);
	}, [profile]);

	if (!profile || isLoading) {
		return null;
	}

	const modal = () => {
		if (isContentModalOpen.open) {
			return (
				<AddContentModal
					isOpen={isContentModalOpen.open}
					setIsOpen={() => setIsContentModalOpen({ open: false })}
					customProfileUuid={profile.uuid}
					content={isContentModalOpen.content}
					contentLength={contentFields.length}
					onDelete={() => {
						if (isContentModalOpen.open === true) {
							const existingContentIndex = contentFields.findIndex(
								(s) => s.uuid === isContentModalOpen.content?.uuid,
							);
							contentFieldArray.remove(existingContentIndex);
						}
					}}
					onSave={(content) => {
						if (isContentModalOpen.open === true) {
							const existingContentIndex = contentFields.findIndex(
								(s) => s.uuid === isContentModalOpen.content?.uuid,
							);
							existingContentIndex == -1
								? contentFieldArray.append(content)
								: contentFieldArray.update(existingContentIndex, content);
						}
					}}
				/>
			);
		} else if (isAudienceModalOpen.open) {
			return (
				<Modal.Root
					isOpen={isAudienceModalOpen.open}
					setIsOpen={() => setIsAudienceModalOpen({ open: false })}
				>
					<AddCustomAudienceDemographic
						metadata={profile?.metadata?.demographics}
						profileUuid={profile?.uuid}
						creatorUuid={creatorUuid}
					/>
				</Modal.Root>
			);
		} else if (isStatModalOpen.open) {
			return (
				<AddStatModal
					isOpen={isStatModalOpen.open}
					setIsOpen={() => setIsStatModalOpen({ open: false })}
					stat={isStatModalOpen.stat}
					isNew={isStatModalOpen.isNew}
					onSave={(stat) => {
						if (isStatModalOpen.open === true) {
							const existingStatIndex = statFields.findIndex(
								(s) => s.name === isStatModalOpen.stat?.name,
							);
							existingStatIndex == -1
								? statFieldArray.append(stat)
								: statFieldArray.update(existingStatIndex, stat);
							form.onSubmit();
						}
					}}
					onDelete={(stat) => {
						if (isStatModalOpen.open === true) {
							const existingStatIndex = statFields.findIndex(
								(s) => s.name === stat.name,
							);
							statFieldArray.remove(existingStatIndex);
							form.onSubmit();
						}
					}}
				/>
			);
		}

		return null;
	};

	return (
		<div className="flex h-full w-full max-w-[640px] flex-col">
			<div className="flex w-full items-center justify-between pb-6">
				<div className="flex items-center gap-4">
					<IconButton
						icon={ArrowLeft}
						size="sm"
						variant="secondary"
						onClick={() => onClickBack(0)}
					/>
					<div className="flex flex-col gap-1">
						<p className="text-header-lg font-repro">
							{form.getValues("title")}
						</p>
						<p className="text-paragraph-sm font-repro text-text-secondary">
							{form.getValues("username")}
						</p>
					</div>
				</div>
				<div className="flex gap-2">
					<IconButton
						icon={PencilSimple}
						variant="secondary"
						size="sm"
						onClick={() => setIsEditProfileModalOpen(true)}
					/>
					<IconButton
						icon={Trash}
						variant="secondary"
						size="sm"
						onClick={() => {
							setIsRemoveModalOpen(true);
						}}
					/>
				</div>
			</div>

			<div className="bg-stroke-tertiary h-px w-full" />
			<ScrollArea className="max-h-full overflow-y-scroll">
				<div className="flex h-full w-full max-w-[640px] flex-col gap-6 pb-8 pt-6">
					<ToggleSwitch
						label="Show profile on Media Kit & One Sheets"
						toggle={isProfileActive}
						onToggle={() => {
							updateCustomProfileActivation.mutateAsync(
								{
									profileUuid: profile.uuid,
									isActive: !isProfileActive,
								},
								{
									onSuccess: () => {
										utils.agency.get.invalidate();
										utils.agency.customProfiles.get.invalidate();
									},
								},
							);
							setIsProfileActive(!isProfileActive);
						}}
					/>

					<div className="bg-stroke-tertiary h-px w-full" />

					<div className="flex flex-col gap-2">
						<Label variant="overline" size="xs" color="secondary">
							Content
						</Label>
						{contentFields.length !== 0 ? (
							<ScrollArea className="w-full min-w-full overflow-x-scroll">
								<div className="inline-flex items-center gap-4">
									<DndContext
										onDragEnd={onDragEndContent}
										sensors={sensors}
										modifiers={[restrictToParentElement]}
									>
										<SortableContext
											items={contentFields.map((post) => post.uuid)}
											strategy={rectSortingStrategy}
										>
											{contentFields.map((post, index) => {
												return (
													<SortablePost
														key={`${index}-${post.uuid}`}
														onEdit={() =>
															setIsContentModalOpen({
																open: true,
																isNew: false,
																content: post,
															})
														}
														post={post}
													/>
												);
											})}
										</SortableContext>
									</DndContext>
								</div>
							</ScrollArea>
						) : null}

						<Button
							variant="secondary"
							leadingIcon={Plus}
							onClick={() =>
								setIsContentModalOpen({
									open: true,
									isNew: true,
									content: undefined,
								})
							}
						>
							Add content
						</Button>
					</div>
					<div className="bg-stroke-tertiary h-px w-full" />
					<div className="flex flex-col gap-2">
						<Label variant="overline" size="xs" color="secondary">
							Stats
						</Label>
						<DndContext
							onDragEnd={onDragEndStat}
							sensors={sensors}
							modifiers={[restrictToParentElement, restrictToVerticalAxis]}
						>
							<SortableContext items={statFields.map((stat) => stat.uuid)}>
								{statFields.map((stat) => {
									return (
										<Stat
											key={stat.uuid}
											stat={stat}
											onToggle={(newStat) => {
												const existingStatIndex = statFields.findIndex(
													(s) => s.name === newStat.name,
												);

												statFieldArray.update(existingStatIndex, newStat);
												form.onSubmit();
											}}
											setIsEditingStat={setIsStatModalOpen}
										/>
									);
								})}
							</SortableContext>
						</DndContext>
						<Button
							variant="secondary"
							leadingIcon={Plus}
							onClick={() =>
								setIsStatModalOpen({ open: true, isNew: true, stat: undefined })
							}
						>
							Add stats
						</Button>
					</div>
					<div className="bg-stroke-tertiary h-px w-full" />
					<div className="flex flex-col gap-2">
						<Label variant="overline" size="xs" color="secondary">
							Audience demographics
						</Label>
						<Button
							variant="secondary"
							leadingIcon={hasDemographics ? PencilSimple : Plus}
							onClick={() =>
								setIsAudienceModalOpen({
									open: true,
									isNew: true,
									metadata: undefined,
								})
							}
						>
							{hasDemographics ? "Edit" : "Add"} audience demographics
						</Button>
					</div>
				</div>
			</ScrollArea>
			{modal()}
			{isRemoveModalOpen ? (
				<RemoveProfileModal
					isOpen={isRemoveModalOpen}
					setIsOpen={setIsRemoveModalOpen}
					remove={() => {
						deleteProfile.mutateAsync(
							{
								uuid: profile.uuid,
							},
							{
								onSuccess: () => {
									utils.agency.get.invalidate();
									utils.agency.customProfiles.get.invalidate();
									onClickBack(0);
								},
							},
						);
					}}
				/>
			) : null}
			{isEditProfileModalOpen ? (
				<EditProfileModal
					isOpen={isEditProfileModalOpen}
					setIsOpen={setIsEditProfileModalOpen}
					onSave={(values) => {
						form.setValue("title", values.title);
						form.setValue("username", values.username);
						form.setValue("accountUrl", values.accountUrl);
						form.onSubmit();
					}}
					title={form.getValues("title") ?? ""}
					username={form.getValues("username") ?? ""}
					accountUrl={form.getValues("accountUrl") ?? ""}
				/>
			) : null}
		</div>
	);
};

interface AddContentModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	onDelete: () => void;
	onSave: (content: CustomProfileContent) => void;
	customProfileUuid: string;
	content: CustomProfileContent | undefined;
	contentLength: number;
}

const AddContentModal: React.FC<AddContentModalProps> = ({
	isOpen,
	setIsOpen,
	onDelete,
	customProfileUuid,
	content,
	onSave,
	contentLength,
}) => {
	const [imageUrl, setImageUrl] = useState(content?.imageUrl ?? "");
	const [uploadUuid, setUploadUuid] = useState<string | undefined>(
		() => undefined,
	);
	const [localImage, setLocalImage] = useState<string>();
	const [photoError, setPhotoError] = useState<boolean>(false);
	const uploadRef = useRef<HTMLInputElement | null>(null);
	const getUploadUrl = trpc.user.getProfilePictureUploadUrl.useMutation();
	const { toast } = useToast();
	const uploadCustomContent =
		trpc.agency.customProfiles.upsertCustomContent.useMutation();
	const deleteContent =
		trpc.agency.customProfiles.deleteCustomProfileContent.useMutation();

	const onUpload = async (file: File) => {
		if (file) {
			if (file.size > 10_000_000) {
				toast({
					title: "That file is too large",
					description: "Please upload a file less than 10MB",
				});
				return;
			}

			const { url, fields, upload } = await getUploadUrl.mutateAsync({
				fileName: file.name,
				fileType: file.type,
				size: file.size,
			});

			const formData = new FormData();
			Object.entries(fields).forEach(([k, v]) => formData.append(k, v));
			formData.append("Content-Type", file.type);
			formData.append("file", file);
			await fetch(url, {
				method: "POST",
				body: formData,
			});
			setLocalImage(URL.createObjectURL(file));
			setUploadUuid(() => upload.uuid);
		}
	};

	const form = useZodForm({
		schema: UpsertCustomProfileContentSchema,
		values: {
			uuid: content?.uuid ?? undefined,
			customProfileUuid: customProfileUuid,
			content: {
				contentUrl: content?.contentUrl ?? "",
				description: content?.description ?? "",
				imageSize: content?.imageSize ?? "square",
				order: content?.order ?? contentLength + 1,
			},
			mediaUuid: "",
		},
		submit: async (data, { onFormError }) => {
			if ((!uploadUuid || uploadUuid === "") && !content?.imageUrl) {
				setPhotoError(true);
			} else {
				uploadCustomContent.mutateAsync(
					{ ...data, mediaUuid: uploadUuid ?? "" },
					{
						onSuccess: (values) => {
							onSave({
								uuid: content?.uuid ?? values.uuid,
								order: content?.order ?? contentLength + 1,
								imageUrl: localImage ?? content?.imageUrl ?? "",
								imageSize: data.content.imageSize,
								contentUrl: data.content.contentUrl,
								description: data.content.description,
							});

							setIsOpen(false);
							form.reset();
						},
						onError: (error) => {
							onFormError(error);
						},
					},
				);
			}
		},
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="lg">
			<Modal.Header
				title={`${content ? "Edit" : "Add"} Content`}
				description="Content will be shown at the top of the profile section."
			/>
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex flex-col gap-6">
							<div className="flex flex-col gap-2">
								<div className="flex flex-col gap-2">
									<Label variant="overline" size="xs" color="secondary">
										Photo
									</Label>
									<div className="flex w-full justify-between gap-4">
										{imageUrl !== "" ? (
											<div className="flex gap-4">
												<img
													src={imageUrl}
													className="h-10 w-10 min-w-10 rounded-lg object-cover object-center"
													alt="content"
												/>
												<Button
													variant="secondary"
													className="w-[104px] min-w-[104px]"
													onClick={(e) => {
														e.preventDefault();
														uploadRef.current?.click();
													}}
												>
													Replace
												</Button>
											</div>
										) : (
											<Button
												variant="secondary"
												leadingIcon={Upload}
												className="w-[160px] min-w-[160px]"
												onClick={(e) => {
													e.preventDefault();
													uploadRef.current?.click();
												}}
											>
												Upload photo
											</Button>
										)}
										<input
											className="hidden"
											type="file"
											ref={uploadRef}
											onChange={(e) => {
												const file = e.target.files?.[0];
												if (file) {
													setImageUrl(URL.createObjectURL(file));
													onUpload(file);
												}
											}}
										/>
										<ZodDropdownInput
											className="w-full"
											size="md"
											placeholder="Select size"
											name="content.imageSize"
											defaultValue="square"
										>
											{[
												{
													name: "vertical",
													displayTitle: "Vertical (9:16)",
													icon: <DeviceMobileSpeaker />,
												},
												{
													name: "square",
													displayTitle: "Square (1:1)",
													icon: <Square />,
												},

												{
													name: "horizontal",
													displayTitle: "Horizontal (16:9)",
													icon: <Rectangle />,
												},
											]
												.filter(nonNull)
												.map((option) => (
													<DropdownInput.Item
														icon={option.icon}
														value={option.name}
														key={option.name}
													>
														{option.displayTitle}
													</DropdownInput.Item>
												))}
										</ZodDropdownInput>
									</div>
								</div>
								{photoError ? (
									<p className="text-red-7 text-paragraph-xs font-repro">
										Please upload a photo
									</p>
								) : null}
							</div>

							<div className="bg-stroke-tertiary h-px w-full" />
							<ZodInput
								name="content.contentUrl"
								label="Link to content"
								placeholder="https://"
							/>
							<ZodTextArea
								name="content.description"
								label="description (optional)"
								placeholder="Add a short description"
							/>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>

			{content ? (
				<Modal.Footer
					buttons="primary-secondary"
					primaryLabel="Save"
					secondaryLabel="Delete"
					layout="separated"
					onSecondaryClicked={() => {
						deleteContent.mutate(
							{
								uuid: content.uuid,
							},
							{
								onSuccess: () => {
									onDelete();
									setIsOpen(false);
								},
							},
						);
					}}
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
					isPrimaryDisabled={false}
				/>
			) : (
				<Modal.Footer
					buttons="primary"
					primaryLabel="Save"
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
					isPrimaryDisabled={false}
				/>
			)}
		</Modal.Root>
	);
};

interface AddStatModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	stat?: SerializedCustomProfileStat;
	onSave: (stat: SerializedCustomProfileStat) => void;
	onDelete: (stat: SerializedCustomProfileStat) => void;
	isNew: boolean;
}

const AddStatModal: React.FC<AddStatModalProps> = ({
	isOpen,
	setIsOpen,
	onDelete,
	onSave,
	stat,
	isNew,
}) => {
	const form = useZodForm({
		schema: CustomProfileStatSchema,
		values: {
			name: stat?.name ?? "",
			displayTitle: stat?.displayTitle ?? "",
			value: stat?.value.toString() ?? "",
			enabled: stat?.enabled ?? true,
		},
		submit: (values) => {
			if (values.displayTitle.length >= 1 && values.value.length >= 1) {
				onSave({
					name: `custom-${values.displayTitle.toLowerCase().charAt(0)}${values.displayTitle.trim().replaceAll(" ", "").slice(1)}`,
					displayTitle: values.displayTitle,
					enabled: true,
					value: values.value,
					uuid: crypto.randomUUID(),
				});
				setIsOpen(false);
			}
			form.reset();
		},
	});

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title={`${isNew ? "Add" : "Edit"} custom stat`} />
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex flex-col gap-6">
							<ZodInput
								name="displayTitle"
								label="Stat Title"
								placeholder="Add stat title"
							/>
							<ZodInput name="value" label="value" placeholder="Add value" />
						</div>
					</form>
				</FormProvider>
			</Modal.Body>

			{stat ? (
				<Modal.Footer
					layout="separated"
					buttons="primary-secondary"
					primaryLabel="Save"
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
					secondaryLabel="Delete"
					onSecondaryClicked={() => {
						onDelete(stat);
						setIsOpen(false);
					}}
					isPrimaryDisabled={!form.formState.isValid}
				/>
			) : (
				<Modal.Footer
					layout="separated"
					buttons="primary"
					primaryLabel="Save"
					onPrimaryClicked={() => {
						form.onSubmit();
					}}
					isPrimaryDisabled={!form.formState.isValid}
				/>
			)}
		</Modal.Root>
	);
};

interface RemoveProfileModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	remove: () => void;
}

const RemoveProfileModal: React.FC<RemoveProfileModalProps> = ({
	isOpen,
	setIsOpen,
	remove,
}) => {
	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header title="Delete Profile?" />
			<Modal.Body>
				<p className="text-paragraph-sm font-repro text-text-secondary">
					Any content and/or stats associated with this profile will be
					permanently removed.
				</p>
			</Modal.Body>
			<Modal.Footer
				primaryLabel="Delete Profile"
				onPrimaryClicked={remove}
				secondaryLabel="Cancel"
				onSecondaryClicked={() => setIsOpen(false)}
				buttons="primary-secondary"
				layout="separated"
				variant="danger"
				isPrimaryDisabled={false}
			/>
		</Modal.Root>
	);
};

interface EditProfileModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	onSave: (values: {
		title: string;
		username: string;
		accountUrl: string;
	}) => void;
	title: string;
	username: string;
	accountUrl: string;
}

const EditProfileModal: React.FC<EditProfileModalProps> = ({
	isOpen,
	setIsOpen,
	onSave,
	title,
	username,
	accountUrl,
}) => {
	const form = useZodForm({
		schema: z.object({
			title: z.string().min(2),
			username: z.string().optional(),
			accountUrl: z.string().url().or(z.literal("")),
		}),
		values: {
			title: title,
			username: username,
			accountUrl: accountUrl,
		},
		submit: async (data) => {
			onSave(data);
			setIsOpen(false);
		},
	});
	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen} size="lg">
			<Modal.Header title="Edit profile" />
			<Modal.Body>
				<FormProvider {...form}>
					<form>
						<div className="flex flex-col gap-6">
							<ZodInput
								name="title"
								label="Title"
								placeholder="Pinterest, X (Twitter), As Seen In, etc."
							/>
							<ZodInput
								name="username"
								label="Name or Username (optional)"
								placeholder="Add name or username"
							/>
							<ZodInput
								name="accountUrl"
								label="Link to Profile (optional)"
								placeholder="Add a link to the profile"
							/>
						</div>
					</form>
				</FormProvider>
			</Modal.Body>

			<Modal.Footer
				buttons="primary"
				primaryLabel="Save"
				onPrimaryClicked={() => {
					form.onSubmit();
				}}
				isPrimaryDisabled={!form.formState.isValid}
			/>
		</Modal.Root>
	);
};

interface StatProps {
	stat: SerializedCustomProfileStat;
	onToggle: (newStat: SerializedCustomProfileStat) => void;
	setIsEditingStat: (statState: StatModalType) => void;
}

const Stat: React.FC<StatProps> = ({ stat, onToggle, setIsEditingStat }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: stat.uuid });
	return (
		<div
			key={stat.uuid}
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
			className="flex h-8 items-center justify-between gap-4 px-2"
		>
			<div className="flex items-center gap-4">
				<DotsSixVertical />
				<ToggleSwitch
					toggle={stat.enabled}
					onToggle={() => {
						onToggle({
							displayTitle: stat.displayTitle,
							name: stat.name,
							value: stat.value,
							enabled: !stat.enabled,
							uuid: stat.uuid,
						});
					}}
				/>
				<div className="flex gap-[10px]">
					<p className="text-paragraph-sm font-repro max-w-[230px] truncate">
						{stat.displayTitle}
					</p>

					<Tag text="Custom" color="pink" />
				</div>
			</div>
			<div className="flex gap-4">
				<p className="text-paragraph-md font-repro max-w-[60px] truncate">
					{stat.value}
				</p>

				<Button
					className="h-6 w-10"
					variant="secondary"
					size="xs"
					onClick={(e) => {
						e.preventDefault();
						setIsEditingStat({
							open: true,
							stat: stat,
							isNew: false,
						});
					}}
				>
					Edit
				</Button>
			</div>
		</div>
	);
};

interface SortablePostProps {
	post: CustomProfileContent;
	onEdit: () => void;
}

export const SortablePost: React.FC<SortablePostProps> = ({ post, onEdit }) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: post.uuid });

	const width = () => {
		if (post.imageSize === "horizontal") {
			return "w-[284px] min-w-[284px]";
		} else if (post.imageSize === "vertical") {
			return "w-[90px] min-w-[90px]";
		} else {
			return "w-[160px] min-w-[160px]";
		}
	};

	return (
		<div
			key={post.contentUrl}
			className={`group/item flex ${width()} cursor-pointer flex-col items-center justify-center gap-2`}
			ref={setNodeRef}
			{...listeners}
			{...attributes}
			style={{
				transform: CSS.Translate.toString(transform),
				transition,
				opacity: isDragging ? 0.75 : 1,
			}}
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				onEdit();
			}}
		>
			<div className="relative h-[160px] w-full rounded-lg object-contain object-center">
				<div
					className="absolute bottom-2 right-2 z-20 hidden cursor-pointer p-2 group-hover/item:block"
					role="button"
					aria-label="Delete post from highlighted posts"
				>
					<div className="bg-surface-hover-1 border-stroke-tertiary absolute bottom-0 right-0 flex h-8 w-8 items-center justify-center rounded-full border backdrop-blur-md transition-all disabled:cursor-not-allowed disabled:opacity-40">
						<PencilSimple />
					</div>
				</div>
				<img
					className="z-10 h-[160px] w-full rounded-lg object-cover object-center transition-all group-hover/item:opacity-40"
					src={post.imageUrl}
					alt={`Thumbnail for the post at ${post.imageUrl}`}
				/>
			</div>
			<DotsSixVertical className="rotate-90" />
		</div>
	);
};
