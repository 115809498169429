import { Input, Modal } from "@withjuly/solisv2";
import { useState } from "react";
import { trpc } from "../Utility/trpc";
import { z } from "zod";

export const AddCreatorEmailModal = ({
	isOpen,
	setIsOpen,
	userUuid,
	name,
	sendEmail,
}: {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	userUuid: string;
	name: string;
	sendEmail: () => void;
}) => {
	const utils = trpc.useContext();
	const addUserEmail = trpc.agency.updateAgencyCreatorEmail.useMutation();
	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string | undefined>(undefined);

	return (
		<Modal.Root isOpen={isOpen} setIsOpen={setIsOpen}>
			<Modal.Header
				title="Add email address"
				description={`${name} does not have an email address set. Add their email address to contact them from July.`}
			/>

			<Modal.Body>
				<Input
					value={email}
					onChange={(e) => {
						if (emailError !== undefined) {
							setEmailError(undefined);
						}

						setEmail(e.target.value);
					}}
					label="Talent email"
					placeholder="Add email address"
					error={emailError !== undefined ? true : false}
					errorMessage={emailError}
				/>
			</Modal.Body>

			<Modal.Footer
				primaryLabel="Save and send email"
				onPrimaryClicked={() => {
					if (!z.string().email().safeParse(email).success) {
						setEmailError("Invalid email");
					} else {
						addUserEmail.mutate(
							{
								userUuid,
								email,
							},
							{
								onSuccess: () => {
									sendEmail();
									setIsOpen(false);
									utils.agency.getCreatorDetails.invalidate();
									utils.agency.getCreatorsPreview.invalidate();
								},
								onError: () => {
									setEmailError("Email already in use.");
								},
							},
						);
					}
				}}
			/>
		</Modal.Root>
	);
};
