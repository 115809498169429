import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { MagnifyingGlass } from "@withjuly/julycons/bold";
import { Input, useToast } from "@withjuly/solisv2";
import { useEffect, useRef, useState } from "react";

interface LocationPickerProps {
	setSelectedPlace: (place: string | undefined) => void;
	label?: string;
	value?: string;
}

export const LocationPicker = ({
	setSelectedPlace,
	label,
	value,
}: LocationPickerProps) => {
	const { toast } = useToast();

	const getGoogleApiKey = () => {
		const apiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
		if (!apiKey) {
			toast({
				title: "Something went wrong.",
				description: "If this issue persists, please contact support",
				variant: "error",
			});
		}
		return apiKey || "";
	};

	return (
		<APIProvider apiKey={getGoogleApiKey()}>
			<div className="autocomplete-control">
				<PlaceAutocomplete
					onPlaceSelect={setSelectedPlace}
					label={label}
					value={value}
				/>
			</div>
		</APIProvider>
	);
};

interface PlaceAutocompleteProps {
	onPlaceSelect: (place: string | undefined) => void;
	label?: string;
	value?: string;
}

const PlaceAutocomplete = ({
	onPlaceSelect,
	label,
	value,
}: PlaceAutocompleteProps) => {
	const [placeAutocomplete, setPlaceAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const places = useMapsLibrary("places");
	const [search, setSearch] = useState(value);

	useEffect(() => {
		if (!places || !inputRef.current) return;

		const options = {
			types: ["(cities)"],
		};

		setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
	}, [places]);

	useEffect(() => {
		if (!placeAutocomplete) return;

		placeAutocomplete.addListener("place_changed", () => {
			const place = placeAutocomplete.getPlace().formatted_address;
			onPlaceSelect(place);
			setSearch(place);
		});
	}, [onPlaceSelect, placeAutocomplete]);

	return (
		<div className="autocomplete-container">
			<Input
				ref={inputRef}
				label={label}
				placeholder="Search location"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				leadingIcon={MagnifyingGlass}
			/>
		</div>
	);
};
